import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Button, Typography, Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface IBillingData {
  id: number;
  invoice: string;
  packageName: string;
  amount: number;
  status: string;
  date: string;
  invoiceUrl: string;
}

interface Props {
  inVoiceDataArray: IBillingData[];
}

const BillingHistory: React.FC<Props> = ({ inVoiceDataArray }) => {
  // const tabularData = [
  //   {
  //     id: 1,
  //     invoice: "INV-001122022",
  //     amount: 10000,
  //     status: "paid",
  //     date: "Dec 1 2023",
  //     invoiceUrl: "",
  //   },
  // ];

  const column: GridColDef<IBillingData>[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1 / 3,
    },
    {
      field: "invoice",
      headerName: "Invoice",
      headerAlign: "left",
      align: "left",
      flex: 2,
      renderCell: ({
        row: { invoice, packageName },
      }: {
        row: IBillingData;
      }) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            {invoice}
          </Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            {packageName}
          </Typography>
        </Box>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row: { amount } }: { row: IBillingData }) => (
        <Typography color="text.secondary" variant="body2">
          USD ${amount}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row: { status } }: { row: IBillingData }) => (
        <Chip
          label={status}
          icon={
            <CircleIcon
              sx={{
                fontSize: 10,
                // color: status.toLowerCase() === "paid" ? "green" : "red",
                color: "inherit",
              }}
            />
          }
          sx={{
            backgroundColor:
              status.toLowerCase() === "paid" ? "#E6F4EA" : "#FDE8E9",
            color: status.toLowerCase() === "paid" ? "#137333" : "#D32F2F",
            fontWeight: "bold",
            borderRadius: "16px",
            paddingX: "5px",
          }}
        />
      ),
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row: { date } }: { row: IBillingData }) => (
        <Typography color="text.secondary" variant="body2">
          {date.split("T")[0]}
        </Typography>
      ),
    },
    {
      field: "invoiceUrl",
      headerName: "View",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: ({ row: { invoiceUrl } }: { row: IBillingData }) => (
        <Button
          variant="text"
          endIcon={<ArrowForwardIcon />}
          sx={{ textTransform: "none", fontWeight: "bold" }}
          onClick={() => window.open(invoiceUrl, "_blank")}
        >
          View Invoice
        </Button>
      ),
    },
  ];
  if (!inVoiceDataArray || inVoiceDataArray.length === 0) return null;
  return (
    <Box>
      <DataGrid rows={inVoiceDataArray} columns={column} />
    </Box>
  );
};

export default BillingHistory;
