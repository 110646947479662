import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  Root: {
    "&>p": {
      marginBottom: "50px",
    },
  },
  plan_container: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    gap: "20px",
    // alignItems: "stretch",
    marginTop: "20px",
  },
}));

export default useStyles;
