import React from "react";
import PricingPlan from "src/components/pages/PricingPlans";
import DynamicHead from "src/components/shared/atoms/helmet";

const PricingPlanPage = () => {
  return (
    <div>
      <DynamicHead title="Subcription" />
      <PricingPlan />
    </div>
  );
};

export default PricingPlanPage;
