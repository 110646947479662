import { gql } from "@apollo/client";

const UPGRADE_SUBSCRIPTION = gql`
  mutation updateSubscription(
    $subscriptionId: ID!
    $newPlanId: ID!
    $newQuantity: Int!
    $billingCycleInterval: String!
    $billingCycleIntervalCount: Int!
    $accessToken: String
  ) {
    updateSubscription(
      subscriptionId: $subscriptionId
      newPlanId: $newPlanId
      newQuantity: $newQuantity
      billingCycleInterval: $billingCycleInterval
      billingCycleIntervalCount: $billingCycleIntervalCount
      accessToken: $accessToken
    ) {
      success
      error
      data {
        id
        status
        current_period_end
        items {
          id
          price
          quantity
        }
      }
    }
  }
`;

export default UPGRADE_SUBSCRIPTION;
