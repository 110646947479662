import * as React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

interface Props {
  open: boolean;
  setOpen: (a: boolean) => void;
}

const EnterpriseModal: React.FC<Props> = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);

  const validationSchema = Yup.object({
    companySize: Yup.string().required("This field is required"),
    agentsCount: Yup.number()
      .required("This field is required")
      .min(1, "At least 1 agent is required"),
  });

  const handleSubmit = (values: any) => {
    console.log("values__", values);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              flex: "1 1 40%",
              borderRight: "1px solid gray",
              padding: "2rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                lineHeight: "1.4",
                marginBottom: "10px",
              }}
            >
              Enterprise Discussion
            </Typography>
            <Typography sx={{ fontSize: "16px" }}>
              Book a 20 minute conversation with us to discuss your customer
              engagement needs. We'll help you;
            </Typography>
            <List>
              {[
                "Introduce you to LiveChat and our suite of products.",
                "Evaluate your potential ROI.",
                "Discuss and set up an implementation plan.",
              ].map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem sx={{ margin: 0 }} key={index}>
                  <ListItemText sx={{ fontSize: "16px" }} primary={item} />
                </ListItem>
              ))}
            </List>
          </Box>
          <Formik
            initialValues={{ companySize: "", agentsCount: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur }: any) => (
              <Form style={{ flex: "1 1 60%", padding: "2rem" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  {/* Company Size */}
                  <InputLabel sx={{ fontWeight: "600", color: "black" }}>
                    How large is your company?
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={touched.companySize && !!errors.companySize}
                  >
                    <Field
                      as={Select}
                      name="companySize"
                      value={values.companySize}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      <MenuItem value="small">Small</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="large">Large</MenuItem>
                    </Field>
                    {touched.companySize && errors.companySize && (
                      <FormHelperText>{errors.companySize}</FormHelperText>
                    )}
                  </FormControl>

                  <InputLabel sx={{ fontWeight: "600", color: "black" }}>
                    How many agents do you plan to start with?
                  </InputLabel>
                  <Field
                    name="agentsCount"
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    value={values.agentsCount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.agentsCount && !!errors.agentsCount}
                    helperText={touched.agentsCount && errors.agentsCount}
                  />

                  {/* Submit Button */}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      marginTop: "1rem",
                      borderRadius: "8px",
                      width: "fit-content",
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default EnterpriseModal;
