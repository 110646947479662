import { gql } from "@apollo/client";

const SETUSERALERT = gql`
  mutation setUserAlert($agentId: ID!) {
    setUserAlert(agentId: $agentId) {
      success
      error
    }
  }
`;
export default SETUSERALERT;
