import { gql } from "@apollo/client";

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $username: String
    $pseudonym: String
    $picture: String
    $number: String
    $status: ID
    $agentlimitchatassign: Int
    $pages: [String]
    $plans: String
    $mainSuperAdminId: ID
  ) {
    updateuser(
      id: $id
      username: $username
      pseudonym: $pseudonym
      picture: $picture
      number: $number
      status: $status
      agentlimitchatassign: $agentlimitchatassign
      pages: $pages
      plans: $plans
      mainSuperAdminId: $mainSuperAdminId
    ) {
      success
      error
    }
  }
`;
export default UPDATE_USER;
