/* eslint-disable no-nested-ternary */
import { Box, Typography } from "@mui/material";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import BillingForm from "../BillingForm";
import useStyles from "./Styles";

interface IProductDetail {
  productName: string;
  productId: string;
  interval: string;
}

interface Props {
  productName: string;
  productDetail: IProductDetail;
  settingsPage?: boolean;
  setShowScreen: any;
  refetch: any;
  formRef: any;
  clientSecret: string;
  setLoader: (a: boolean) => void;
}

const BillingPortal: React.FC<Props> = ({
  settingsPage = false,
  formRef,
  clientSecret,
  setLoader,
}) => {
  const { classes } = useStyles();
  const { hasTrial, plans } = useActiveUserDetail();

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Typography className={classes.heading}>
          {settingsPage ? "Update" : "Add"} payment method
        </Typography>

        <Typography className={classes.heading}>
          (
          {hasTrial === "1" && plans && plans.toLowerCase() === "freetrial"
            ? "Required"
            : settingsPage
            ? "Optional"
            : "Required"}
          )
        </Typography>
      </Box>
      <Box className={classes.mainContainer}>
        <BillingForm
          formRef={formRef}
          clientSecret={clientSecret}
          setLoader={setLoader}
        />
      </Box>
    </Box>
  );
};

export default BillingPortal;
