/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import GetProductFeatures from "src/graphql/query/productandpricing/getProductFeatures";
import CheckIcon from "@mui/icons-material/Check";
import { enqueueSnackbar } from "notistack";
import StarIcon from "@mui/icons-material/Star";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import { IPlan, IFeatures, IPrices } from "../dataTypes";
import useStyles from "./Styles";

interface IProps {
  keyIndex: number;
  handleOnClick: any;
  packageDetailsLoader: boolean;
  plan: string;
  setting?: boolean;
}

type IInterface = IProps & IPlan;

const Index: React.FC<IInterface> = ({
  keyIndex,
  name,
  description,
  prices,
  handleOnClick,
  packageDetailsLoader,
  plan,
  setting = false,
}) => {
  const { classes } = useStyles();

  const { plans } = useActiveUserDetail();

  const [productFeaturesList, setProductFeaturesList] = useState<IFeatures[]>(
    [],
  );

  const [
    getProductFeatures,
    {
      loading: getProductFeaturesQueryLoading,
      error: getProductFeaturesQueryError,
      data: getProductFeaturesQueryResult,
    },
  ] = useLazyQuery(GetProductFeatures, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (!packageDetailsLoader && name) {
      getProductFeatures();
    }
  }, [packageDetailsLoader]);

  useEffect(() => {
    if (getProductFeaturesQueryResult?.getProductFeatures) {
      try {
        const productFeatures =
          typeof JSON.parse(
            getProductFeaturesQueryResult.getProductFeatures,
          ) === "string"
            ? JSON.parse(
                JSON.parse(getProductFeaturesQueryResult.getProductFeatures),
              )
            : JSON.parse(getProductFeaturesQueryResult.getProductFeatures);

        setProductFeaturesList(productFeatures);
      } catch (err) {
        enqueueSnackbar("Error parsing product features data", {
          variant: "error",
        });
      }
    }
  }, [getProductFeaturesQueryResult]);

  useEffect(() => {
    if (getProductFeaturesQueryError) {
      enqueueSnackbar(getProductFeaturesQueryError.message, {
        variant: "error",
      });
    }
  }, [getProductFeaturesQueryError]);

  const getPricesAccordingToPlan = (pricesList: IPrices[]) => {
    const currentPlan = plan === "annual" ? "year" : "month";
    if (currentPlan === "month") {
      const montlyPrice = pricesList.find((item) => item.interval === "month");

      if (montlyPrice) {
        return Math.ceil(montlyPrice.price / 100);
      }
    }

    const annualPrice = pricesList.find(
      (item) => item.interval === "year" && item.intervalCount === 1,
    );

    if (annualPrice) {
      return Math.ceil(annualPrice.price / 100 / 12);
    }
    return null;
  };

  const slicedProductFeaturesList = setting
    ? productFeaturesList.slice(0, 4)
    : productFeaturesList;
  return (
    <Box key={keyIndex} className={classes.package_container}>
      {name.toLowerCase() === "business" && (
        <Box className={classes.recommendation_text}>
          <img
            src="https://cdn.livechat-static.com/app/img/subscription/arrow.png?4ce8798db7e9736c0930d1e7a51ebb95"
            alt=""
            className={classes.recommendation_arrow}
          />
          <Typography>
            Recommended for
            <br />
            <span style={{ fontWeight: "600" }}> businesses of your size</span>
          </Typography>
        </Box>
      )}
      <Box className={classes.package_container_header_container}>
        <Typography className={classes.package_container_name}>
          {name}
        </Typography>
        {name.toLowerCase() === "standard" && (
          <Box className={classes.package_container_plan}>
            <StarIcon sx={{ width: "15px", height: "15px" }} />
            <Typography>Popular</Typography>
          </Box>
        )}
      </Box>
      <Typography className={classes.package_container_description}>
        {description}
      </Typography>
      <Box className={classes.package_container_pricing_container}>
        <Box className={classes.package_container_pricing_figure}>
          <span className={classes.package_container_pricing_figure_dollar}>
            $
          </span>
          {getPricesAccordingToPlan(prices)}
        </Box>
        <Typography className={classes.perUserText}>
          Per user and per month
        </Typography>
      </Box>
      <Button
        onClick={handleOnClick}
        className={`${classes.package_container_btn} ${
          plans === "freetrial"
            ? ""
            : name.toLowerCase() === plans?.toLowerCase() &&
              classes.package_container_btn_active
        }`}
      >
        {plans === "freetrial"
          ? "Subscribe Now"
          : name.toLowerCase() === plans?.toLowerCase()
          ? "Upgrade Current Plan"
          : "Switch to this Plan"}{" "}
      </Button>
      <Divider />
      <Box className={classes.package_container_features}>
        <Typography>Features:</Typography>
        {getProductFeaturesQueryLoading ? (
          <Box className={classes.progressIndicator}>
            <CircularProgress color="primary" size={20} />
          </Box>
        ) : (
          slicedProductFeaturesList.map((categoryItem) =>
            categoryItem.features.slice(0, 2).map((feature) => (
              <Box className={classes.package_container_features_text}>
                <CheckIcon
                  className={classes.package_container_features_icon}
                />
                {Object.entries(feature.allowed).map(([key, value]) => (
                  <Typography key={key}>
                    {key.toLowerCase() === name.toLowerCase() && !!value
                      ? feature.name
                      : null}
                  </Typography>
                ))}
              </Box>
            )),
          )
        )}
      </Box>
    </Box>
  );
};

export default Index;
