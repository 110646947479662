import { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useMutation } from "@apollo/client";
import UPDATE_DEFAULT_PAYMENT_METHOD_FOR_CUSTOMERS from "src/graphql/mutation/settings/paymentMethod/updatePaymentMethod";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";

interface ICheckoutForm {
  formRef: any;
  setLoader: (a: boolean) => void;
}
const CheckoutForm: React.FC<ICheckoutForm> = ({ formRef, setLoader }) => {
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [message, setMessage] = useState<String | undefined>("");
  const [isProcessing, setIsProcessing] = useState(false);

  //
  const { stripeId: customerId } = useActiveUserDetail();
  // update payment method work
  const [
    updateDefaultPaymentMethodForCustomers,
    {
      //   loading: updateDefaultPaymentMethodForCustomersLoading,
      error: updateDefaultPaymentMethodForCustomersError,
      data: updateDefaultPaymentMethodForCustomersResult,
    },
  ] = useMutation(UPDATE_DEFAULT_PAYMENT_METHOD_FOR_CUSTOMERS);

  useEffect(() => {
    if (
      updateDefaultPaymentMethodForCustomersResult &&
      updateDefaultPaymentMethodForCustomersResult.updateDefaultPaymentMethodForCustomer
    ) {
      if (
        updateDefaultPaymentMethodForCustomersResult.updateDefaultPaymentMethodForCustomer
      ) {
        const { success, result } =
          updateDefaultPaymentMethodForCustomersResult.updateDefaultPaymentMethodForCustomer;
        if (!success) {
          enqueueSnackbar(result, {
            variant: "error",
          });
        } else {
          navigate("/");
        }
      }
    }
  }, [updateDefaultPaymentMethodForCustomersResult]);

  useEffect(() => {
    if (updateDefaultPaymentMethodForCustomersError) {
      enqueueSnackbar(updateDefaultPaymentMethodForCustomersError.message, {
        variant: "error",
      });
    }
  }, [updateDefaultPaymentMethodForCustomersError]);
  //

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    setLoader(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    console.log(paymentIntent, "paymentIntent");
    // if (error?.type === "card_error" || error?.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occured.");
    // }
    //
    if (paymentIntent?.status === "succeeded") {
      setMessage("Payment successful! 🎉");
    } else if (
      error?.type === "card_error" ||
      error?.type === "validation_error"
    ) {
      setMessage(error.message || "A payment error occurred.");
    }

    setIsProcessing(false);
    setLoader(false);
    //
    enqueueSnackbar("Payment has been done successfully", {
      variant: "success",
    });
    //
    if (paymentIntent?.payment_method)
      updateDefaultPaymentMethodForCustomers({
        variables: {
          customerId,
          paymentMethodId: paymentIntent.payment_method,
        },
      });
    // navigate("/");
  };

  return (
    <form ref={formRef} id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        type="submit"
        disabled={isProcessing || !stripe || !elements}
        id="submit"
      >
        {/* <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span> */}
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default CheckoutForm;
