import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Chip,
  CircularProgress,
} from "@mui/material";

interface CancelSubscriptionModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  currentSubscriptionData: any;
  loader: boolean;
}

const cancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({
  open,
  onClose,
  onDelete,
  currentSubscriptionData,
  loader,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.2rem" }}
      >
        Are you sure you want to cancel this subscription?
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography variant="body2" color="text.secondary">
          This subscrpition will be cancelled.
        </Typography>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 1,
            bgcolor: "#F4F6F8",
            p: 2,
            borderRadius: 2,
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "5px",
            }}
          >
            <CreditCardIcon sx={{ color: "gray" }} />
            <Typography variant="body2" fontWeight="bold">
              Credit card
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {cardBrand} ending in {cardExpiry}
          </Typography>
        </Box> */}
        {currentSubscriptionData === undefined ? (
          <CircularProgress
            color="primary"
            sx={{ marginTop: "5px" }}
            size={25}
          />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box display="flex" alignItems="center" gap={7} mt={1}>
              <Box display="flex" alignItems="center" gap={1} mt={1}>
                <Typography variant="h6" fontWeight="bold">
                  {currentSubscriptionData.packageName}
                </Typography>
                <Chip
                  label={currentSubscriptionData.interval}
                  color="success"
                  size="small"
                />
              </Box>

              <Box display="flex" alignItems="center" gap={1} mt={1}>
                <Typography variant="h4" fontWeight="bold">
                  ${currentSubscriptionData.price}
                </Typography>
                <Typography color="textSecondary">
                  per {currentSubscriptionData.interval}
                </Typography>
              </Box>
            </Box>
            <Typography mt={1} color="textSecondary" sx={{ marginTop: "1rem" }}>
              Renewal date: {currentSubscriptionData.renewalDate.split("T")[0]}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
        <Button onClick={onClose} variant="outlined" sx={{ flex: 1, mr: 1 }}>
          Cancel
        </Button>
        <Button
          onClick={onDelete}
          variant="contained"
          color="error"
          sx={{ flex: 1 }}
        >
          Cancel{" "}
          {loader && (
            <CircularProgress
              sx={{ color: "white", marginLeft: "10px" }}
              size={15}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default cancelSubscriptionModal;
