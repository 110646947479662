import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import { useSnackbar } from "notistack";
import { useQuery } from "@apollo/client";
import GetProductFeatures from "src/graphql/query/productandpricing/getProductFeatures";
import useStyles from "./Styles";
import EnterpriseModal from "../RequestCallModal";
import { IPrices, IPlan, IFeatures } from "../dataTypes";

interface Props {
  products: IPlan[];
  loader: boolean;
  handlePackagePurchase: any;
  handleClose: any;
  plan: string;
  // setPlan: (a: string) => void;
  setPlan: React.Dispatch<React.SetStateAction<string>>;
}

const PricingData: React.FC<Props> = ({
  products,
  loader,
  handlePackagePurchase,
  handleClose,
  plan,
  setPlan,
}) => {
  const { classes } = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [productFeaturesList, setProductFeaturesList] = useState<IFeatures[]>(
    [],
  );

  const {
    // loading: getProductFeaturesQueryLoading,
    error: getProductFeaturesQueryError,
    data: getProductFeaturesQueryResult,
  } = useQuery(GetProductFeatures, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (
      getProductFeaturesQueryResult &&
      getProductFeaturesQueryResult.getProductFeatures
    ) {
      try {
        const productFeatures =
          typeof JSON.parse(
            getProductFeaturesQueryResult.getProductFeatures,
          ) === "string"
            ? JSON.parse(
                JSON.parse(getProductFeaturesQueryResult.getProductFeatures),
              )
            : JSON.parse(getProductFeaturesQueryResult.getProductFeatures);

        setProductFeaturesList([...productFeatures]);
      } catch (err) {
        enqueueSnackbar("Error parsing product features data", {
          variant: "error",
        });
      }
    }
  }, [getProductFeaturesQueryResult]);

  useEffect(() => {
    if (getProductFeaturesQueryError) {
      enqueueSnackbar(getProductFeaturesQueryError.message, {
        variant: "error",
      });
    }
  }, [getProductFeaturesQueryError]);

  const { hasTrial } = useActiveUserDetail();

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlan(event.target.value);
  };

  const definedPlans = ["Basic", "Standard", "Business"];

  const getPricesAccordingToPlan = (pricesList: IPrices[]) => {
    const currentPlan = plan === "annual" ? "year" : "month";
    if (currentPlan === "month") {
      const montlyPrice = pricesList.find((item) => item.interval === "month");

      if (montlyPrice) {
        return Math.ceil(montlyPrice.price / 100);
      }
    }

    const annualPrice = pricesList.find(
      (item) => item.interval === "year" && item.intervalCount === 1,
    );

    if (annualPrice) {
      return Math.ceil(annualPrice.price / 100 / 12);
    }
    return null;
  };

  const selectFeatureByPackage = useCallback(
    (bool: number, text: string | React.ReactNode, checkIndex: number) => {
      if (bool === 1)
        return (
          <Box key={checkIndex} className={classes.selectedFeatureBox}>
            <CheckCircleOutlineIcon className={classes.checkIcon} />
          </Box>
        );
      if (bool === 0)
        return (
          <Box key={checkIndex} className={classes.selectedFeatureBox}>
            <span className={classes.notCheckedIcon} />
          </Box>
        );
      return (
        <Box key={checkIndex} className={classes.textOfFeat}>
          {text}
        </Box>
      );
    },
    [],
  );

  return (
    <Box
      className={classes.container}
      sx={{ maxHeight: "83vh", overflowY: "auto" }}
    >
      <EnterpriseModal open={openModal} setOpen={setOpenModal} />
      <Box>
        <Box className={classes.upperBox}>
          <Typography>Choose the plan to suit your business</Typography>
          <Box className={classes.planHeaderContainer}>
            <RadioGroup value={plan} onChange={handleChange}>
              <FormControlLabel
                style={{ width: "fit-content" }}
                value="annual"
                control={<Radio color="primary" />}
                label={
                  <Typography className={classes.intervalText}>
                    Billed annually
                  </Typography>
                }
              />
              <FormControlLabel
                style={{ width: "fit-content" }}
                value="monthly"
                control={<Radio color="primary" />}
                label={
                  <Typography className={classes.intervalText}>
                    Billed monthly
                  </Typography>
                }
              />
            </RadioGroup>
            {/* Other Columns */}

            {loader
              ? definedPlans.map((item) => (
                  <Box
                    className={classes.planNameContainer}
                    sx={{ position: "relative" }}
                  >
                    {item.toLowerCase() === "business" && (
                      <Typography className={classes.popularPlanText}>
                        MOST POPULAR
                      </Typography>
                    )}
                    <Typography className={classes.planNameText}>
                      {item}
                    </Typography>
                    <Typography className={classes.planNamePrice}>
                      <CircularProgress
                        sx={{ color: "#4d1277", marginTop: "7px" }}
                        size={15}
                      />
                    </Typography>
                    {item.toLowerCase() === "enterprise" ? (
                      <Button
                        className={classes.planNameBtnEnterprise}
                        style={{ marginTop: "10px" }}
                      >
                        Request a call
                      </Button>
                    ) : (
                      <Button
                        className={classes.planNameBtn}
                        style={{ marginTop: "10px" }}
                      >
                        Choose plan
                      </Button>
                    )}
                  </Box>
                ))
              : products.map((item) => (
                  <Box
                    className={classes.planNameContainer}
                    sx={{ position: "relative" }}
                  >
                    {item.name.toLowerCase() === "business" && (
                      <Typography className={classes.popularPlanText}>
                        MOST POPULAR
                      </Typography>
                    )}
                    <Typography className={classes.planNameText}>
                      {item.name}
                    </Typography>
                    <Typography className={classes.planNamePrice}>
                      {item.name.toLowerCase() === "enterprise" ? (
                        <span style={{ fontWeight: "600" }}>Custom price</span>
                      ) : (
                        <>
                          <span style={{ fontWeight: "600" }}>
                            {" "}
                            {getPricesAccordingToPlan(item.prices)}
                          </span>{" "}
                          per agent / mo
                        </>
                      )}
                    </Typography>
                    {item.name.toLowerCase() === "enterprise" ? (
                      <Button
                        onClick={handleOpenModal}
                        className={classes.planNameBtnEnterprise}
                      >
                        Request a call
                      </Button>
                    ) : (
                      <Button
                        className={classes.planNameBtn}
                        disabled={
                          !!hasTrial && item.name.toLowerCase() === "free_trial"
                        }
                        onClick={() => {
                          handlePackagePurchase(
                            item.prices,
                            item.name,
                            item.productId,
                          );
                          handleClose();
                        }}
                      >
                        {!!hasTrial &&
                        item.name.toLowerCase() === "free_trial" ? (
                          <Typography
                            sx={{
                              color: "white",
                              textTransform: "capitalize",
                              margin: "0 -7px",
                            }}
                          >
                            Free_Trial is active
                          </Typography>
                        ) : (
                          "Choose plan"
                        )}
                      </Button>
                    )}
                  </Box>
                ))}
          </Box>
        </Box>
        {!productFeaturesList || productFeaturesList.length === 0 ? (
          <Box className={classes.loaderBox}>
            <CircularProgress className={classes.loaderStyle} />
          </Box>
        ) : (
          productFeaturesList.map((item: IFeatures, itemIndex: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={itemIndex}>
              <Typography className={classes.categoryText}>
                {item.category}
              </Typography>
              {item.features.map((feature, featureIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={featureIndex} className={classes.planFeatureRow}>
                  <Box className={classes.featureTextBox}>
                    <Typography className={classes.featureText}>
                      {feature.name}
                    </Typography>
                    <Tooltip title={feature.name} arrow placement="right">
                      <ErrorOutlineIcon className={classes.tooltip} />
                    </Tooltip>
                  </Box>
                  {Object.entries(feature.allowed).map(
                    ([key, value], index) => {
                      return selectFeatureByPackage(value, key, index);
                    },
                  )}
                </Box>
              ))}
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

export default PricingData;
