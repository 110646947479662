import * as React from "react";
import { Box, Modal } from "@mui/material";
import { IPlan } from "../dataTypes";
import PricingData from "./pricingData";

const style = {
  position: "absolute",
  top: "47%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "83%",
  bgcolor: "background.paper",
  borderRadius: "8px",
};

interface Props {
  open: boolean;
  setOpen: (a: boolean) => void;
  products: IPlan[];
  handlePackagePurchase: any;
  loader: boolean;
  plan: string;
  // setPlan: (a: string) => void;
  setPlan: React.Dispatch<React.SetStateAction<string>>;
}

const FeatureModal: React.FC<Props> = ({
  open,
  setOpen,
  products,
  loader,
  handlePackagePurchase,
  plan,
  setPlan,
}) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <PricingData
          products={products}
          loader={loader}
          handlePackagePurchase={handlePackagePurchase}
          handleClose={handleClose}
          plan={plan}
          setPlan={setPlan}
        />
      </Box>
    </Modal>
  );
};

export default FeatureModal;
