/* eslint-disable no-nested-ternary */
import React from "react";
import { Box, Typography, Button, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface IPaymentCard {
  paymentId: string;
  brand: string;
  cvc: string; // Last 4 digits of the card
  expDate: string; // Expiry Date
  isDefault: boolean; // Is it default?
  isExpired: boolean; // Is it expired?
  onDelete: (paymentId: string, brand: string, expiry: string) => void; // Function to handle delete
  onSetDefault: () => void;
}

const PaymentCard: React.FC<IPaymentCard> = ({
  paymentId,
  brand,
  cvc,
  expDate,
  isDefault,
  isExpired,
  onDelete,
  onSetDefault,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "12px",
        padding: "12px",
        backgroundColor: "#F8FAFC",
        width: "100%",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Left Section */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <img
          //   src="https://upload.wikimedia.org/wikipedia/commons/0/04/Visa.svg"
          //   alt="Visa"
          src={
            brand === "Visa"
              ? "https://upload.wikimedia.org/wikipedia/commons/0/04/Visa.svg"
              : "https://upload.wikimedia.org/wikipedia/commons/b/b7/MasterCard_Logo.svg"
          }
          alt={brand}
          width={60}
          style={{
            border: "1px solid #64748B",
            borderRadius: "12px",
          }}
        />
        <Box>
          <Typography sx={{ fontWeight: 600 }}>
            {" "}
            {brand} ending in {cvc}
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#64748B" }}>
            Exp. date {expDate}
          </Typography>
        </Box>
      </Box>

      {/* Right Section */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {isDefault ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1E293B",
              color: "white",
              textTransform: "none",
              fontSize: "12px",
              borderRadius: "8px",
              padding: "5px 10px",
              minWidth: "auto",
              "&:hover": { backgroundColor: "#0F172A" },
            }}
          >
            Default
          </Button>
        ) : isExpired ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F43F5E",
              color: "white",
              textTransform: "none",
              fontSize: "12px",
              borderRadius: "8px",
              padding: "5px 10px",
              minWidth: "auto",
              "&:hover": { backgroundColor: "#E11D48" },
            }}
          >
            Expired
          </Button>
        ) : (
          <Button
            variant="text"
            sx={{ color: "#3B82F6", fontSize: "12px", textTransform: "none" }}
            onClick={onSetDefault}
          >
            Set as Default
          </Button>
        )}

        {isDefault ? (
          <Tooltip
            placement="top-start"
            title="Before deleting the default one, any other payment method is required to be set as default"
          >
            <span style={{ cursor: "pointer" }}>
              {" "}
              <IconButton sx={{ color: "#64748B" }} disabled>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <IconButton
            sx={{ color: "#64748B" }}
            onClick={() => onDelete(paymentId, brand, expDate)}
          >
            <DeleteIcon />
          </IconButton>
        )}

        {/* {!isDefault && (
          <IconButton
            sx={{ color: "#64748B" }}
            onClick={() => onDelete(paymentId, brand, expDate)}
          >
            <DeleteIcon />
          </IconButton>
        )} */}
      </Box>
    </Box>
  );
};

export default PaymentCard;
