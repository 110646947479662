import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import expressConfig from "src/config/express.json";
import CheckoutForm from "./CheckoutForm";

const env = process.env.NODE_ENV || "development";
const stripePublishableKey = expressConfig[env].STRIPE_PUB_KEY;
const stripePromise = loadStripe(stripePublishableKey);

interface IBillingForm {
  formRef: any;
  clientSecret: string;
  setLoader: (a: boolean) => void;
}

const BillingForm: React.FC<IBillingForm> = ({
  formRef,
  clientSecret,
  setLoader,
}) => {
  // ✅ Return `null` instead of an empty string ("")
  if (!clientSecret || !stripePromise) return null;

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <CheckoutForm formRef={formRef} setLoader={setLoader} />
    </Elements>
  );
};

export default BillingForm;

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import expressConfig from "src/config/express.json";
// import CheckoutForm from "./CheckoutForm";

// const env = process.env.NODE_ENV || "development";
// const stripePublishableKey = expressConfig[env].STRIPE_PUB_KEY;
// const stripePromise = loadStripe(stripePublishableKey);

// interface IBillingForm {
//   formRef: any;
//   clientSecret: string;
//   setLoader: (a: boolean) => void;
// }

// const BillingForm: React.FC<IBillingForm> = ({
//   formRef,
//   clientSecret,
//   setLoader,
// }) => {
//   return (
//     clientSecret &&
//     stripePromise && (
//       <Elements stripe={stripePromise} options={{ clientSecret }}>
//         <CheckoutForm formRef={formRef} setLoader={setLoader} />
//       </Elements>
//     )
//   );
// };

// export default BillingForm;
