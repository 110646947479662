import { gql } from "@apollo/client";

const UPDATE_DEFAULT_PAYMENT_METHOD_FOR_CUSTOMERS = gql`
  mutation updateDefaultPaymentMethodForCustomer(
    $customerId: String!
    $paymentMethodId: String!
  ) {
    updateDefaultPaymentMethodForCustomer(
      customerId: $customerId
      paymentMethodId: $paymentMethodId
    ) {
      success
      error
      result {
        customerId
        defaultPaymentMethod
      }
    }
  }
`;

export default UPDATE_DEFAULT_PAYMENT_METHOD_FOR_CUSTOMERS;
