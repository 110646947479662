interface UserDetail {
  id: string;
  name: string;
  mainSuperAdminId: string;
  managerId: null | string;
  picture: null | string;
  number: null | string;
  paneltype: string;
  pseudonym: string;
  username: string;
  plans: string;
  hasTrial: string;
  endDate: string;
  accessPeriodEndDate: string;
  alert: string;
  subscriptionId: string;
  invoice_url: string;
  stripe_id: string;
  currentCount: number;
  maxCount: number;
  stripe_subscription_status: string;
}

export default function useActiveUserDetail() {
  const activeUserDetail = localStorage.getItem("ActiveUserdetail");
  const data: UserDetail = activeUserDetail
    ? JSON.parse(activeUserDetail)
    : null;

  return {
    agentId: data?.id,
    name: data?.name,
    username: data?.username,
    mainSuperAdminId: Number(data?.mainSuperAdminId),
    managerId: data?.managerId,
    panelType: data?.paneltype,
    number: data?.number,
    picture: data?.picture,
    pseudonym: data?.pseudonym,
    plans: data?.plans,
    hasTrial: data?.hasTrial,
    endDate: data?.endDate,
    accessPeriodEndDate: data?.accessPeriodEndDate,
    alert: data?.alert,
    subscriptionId: data?.subscriptionId,
    invoice_url: data?.invoice_url,
    stripeId: data?.stripe_id,
    currentCount: data?.currentCount,
    maxCount: data?.maxCount,
    stripe_subscription_status: data?.stripe_subscription_status,
  };
}
