import * as React from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStyle from "./Styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  padding: "20px",
  textAlign: "center",
};

interface Props {
  open: boolean;
  setOpen: (a: boolean) => void;
}

const PlanPurchaseModal: React.FC<Props> = ({ open, setOpen }) => {
  const { classes } = useStyle();
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="success-modal-title"
    >
      <Box sx={style}>
        {/* Informational Text */}
        <Typography variant="h6" className={classes.headingStyle}>
          Access Period Expired
        </Typography>
        <Typography variant="body2" className={classes.textStyle}>
          The access period in the <strong>Basic</strong> plan has exceeded 15
          days. As a result, your pages have been disabled. To enable them
          again, please upgrade your plan.
        </Typography>

        {/* Buttons */}
        <Button
          className={`${classes.buttonStyle} ${classes.cancelButton}`}
          variant="contained"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className={`${classes.buttonStyle} ${classes.deleteButton}`}
          variant="contained"
          onClick={() => navigate("/settings/upgrade-plan")}
        >
          Upgrade the Plan
        </Button>
      </Box>
    </Modal>
  );
};

export default PlanPurchaseModal;
