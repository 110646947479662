import * as React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const style = {
  // position: "absolute",
  // top: "47%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: "80%",
  // height: "83%",
  // bgcolor: "background.paper",
  // borderRadius: "8px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

interface Props {
  open: boolean;
  setOpen: (a: boolean) => void;
  planName: string;
  amount: number;
  onDashboardClick: () => void;
  onReceiptClick: () => void;
}

const PlanPurchaseModal: React.FC<Props> = ({
  open,
  setOpen,
  planName,
  amount,
  onDashboardClick,
  onReceiptClick,
}) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="success-modal-title"
    >
      <Box sx={style}>
        <CheckCircleIcon sx={{ fontSize: 50, color: "green" }} />
        <Typography
          id="success-modal-title"
          variant="h6"
          sx={{ mt: 2, fontWeight: "bold" }}
        >
          Success! Your Plan is Activated
        </Typography>
        <Typography sx={{ mt: 2, color: "text.secondary" }}>
          Your payment of <b>${amount}</b> for the <b>{planName}</b> has been
          successfully processed.
        </Typography>
        <Box sx={{ mt: 4, display: "flex", justifyContent: "center", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onDashboardClick}
          >
            Go to Dashboard
          </Button>
          <Button variant="outlined" color="primary" onClick={onReceiptClick}>
            Download Receipt
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PlanPurchaseModal;
