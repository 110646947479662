/* eslint-disable no-nested-ternary */
import { useState, useEffect, useMemo } from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import FeatureModal from "src/components/pages/PricingPlans/FeaturesModal";
import { IPlan, IPrices, ISelectedProductDetails } from "../dataTypes";
import Icon from "./Logo";
import useStyles from "./Styles";

interface Props {
  selectedProductDetail: ISelectedProductDetails;
  plan: string;
  products: IPlan[];
  handlePackagePurchase: any;
  loader: boolean;
  setPlan: React.Dispatch<React.SetStateAction<string>>;
  setCalculatedCutPrice: any;
  setCalculatedPrice: any;
  setPerPeriodName: any;
  setAgentsNumber: any;
  agentsNumber: any;
  setPriceId: any;
}

const BillingPortal: React.FC<Props> = ({
  selectedProductDetail,
  setCalculatedCutPrice,
  setCalculatedPrice,
  setPerPeriodName,
  setAgentsNumber,
  agentsNumber,
  setPriceId,
  plan,
  products,
  loader,
  handlePackagePurchase,
  setPlan,
}) => {
  const { classes } = useStyles();
  // initially setting period based on plan coming from parent
  const [period, setPeriod] = useState(
    plan === "monthly" ? "month-1" : "year-1",
  );

  const [selectedInterval, setSelectedInterval] = useState({
    interval: plan === "monthly" ? "month" : "year",
    intervalCount: 1,
  });
  // initially setting yearsInNumber based on plan coming from parent

  useEffect(() => {
    setPeriod(plan === "monthly" ? "month-1" : "year-1");
    setSelectedInterval({
      interval: plan === "monthly" ? "month" : "year",
      intervalCount: 1,
    });
  }, [plan]);

  const [openFeatureModal, setOpenFeatureModal] = useState(false);

  const handlePlanChange = (e: any) => {
    setPeriod(e.target.value);

    const interval = e.target.value.split("-")[0];
    const intervalCount = Number(e.target.value.split("-")[1]);

    setSelectedInterval({
      interval,
      intervalCount,
    });
  };

  const getPricesAccordingToPlan = (pricesList: IPrices[]) => {
    const interval = selectedInterval.interval || "month";
    const intervalCount = selectedInterval.intervalCount || 1;

    console.log(interval, intervalCount, "interval and count");
    if (interval === "month") {
      const montlyPrice = pricesList.find((item) => item.interval === "month");

      if (montlyPrice) {
        return Math.ceil(montlyPrice.price / 100);
      }
    }

    if (interval === "year") {
      console.log(pricesList, "pricesList");
      const annualPrice = pricesList.find(
        (item) =>
          item.interval === "year" && item.intervalCount === intervalCount,
      );
      console.log(annualPrice, "annualPrice");
      if (annualPrice) {
        return Math.ceil(annualPrice.price / 100 / (12 * intervalCount));
      }
    }

    return null;
  };

  const getPriceAccordingToPlanString = useMemo(
    () => getPricesAccordingToPlan(selectedProductDetail.price),
    [selectedInterval],
  );
  const calculatedCutPrice = () => {
    if (selectedInterval.interval === "month") {
      return 1;
    }

    const monthlyPrice =
      selectedProductDetail.price.find((curr) => curr.interval === "month")
        ?.price || 0;

    const cutPrice =
      (monthlyPrice / 100) * 12 * selectedInterval.intervalCount * agentsNumber;

    return cutPrice;
  };

  const calculatedPrice = () => {
    const packagePriceBasedOnIntervalAndCount =
      selectedProductDetail.price.find(
        (curr) =>
          curr.interval === selectedInterval.interval &&
          curr.intervalCount === selectedInterval.intervalCount,
      );

    if (packagePriceBasedOnIntervalAndCount) {
      return (packagePriceBasedOnIntervalAndCount.price / 100) * agentsNumber;
    }

    return 0;
  };

  const perPeriodName = () => {
    if (selectedInterval.interval === "month") return "mo";

    switch (selectedInterval.intervalCount) {
      case 1:
        return "yr";
        break;
      case 2:
        return "2yr";
        break;
      case 3:
        return "3yr";
        break;

      default:
        return "";
        break;
    }
  };

  const getPriceId = () => {
    const selectedPlanPriceObject = selectedProductDetail.price.find(
      (curr) =>
        curr.interval === selectedInterval.interval &&
        curr.intervalCount === selectedInterval.intervalCount,
    );
    if (selectedPlanPriceObject) {
      return selectedPlanPriceObject?.priceId;
    }
    return "";
  };

  useEffect(() => {
    setPriceId(getPriceId());
  }, [selectedInterval]);

  useEffect(() => {
    setCalculatedCutPrice(calculatedCutPrice());
    setCalculatedPrice(calculatedPrice());
    setPerPeriodName(perPeriodName());
  }, [selectedInterval, agentsNumber]);

  return (
    <>
      <FeatureModal
        open={openFeatureModal}
        setOpen={setOpenFeatureModal}
        loader={loader}
        products={products}
        handlePackagePurchase={handlePackagePurchase}
        plan={plan}
        setPlan={setPlan}
      />
      <Box sx={{ width: "100%" }}>
        <Typography className={classes.heading}>Your subscription</Typography>
        <Box className={classes.mainContainer}>
          {/* left container */}

          <Box className={classes.leftContainer}>
            <Icon />
            <Typography className={classes.planText}>
              {selectedProductDetail.productName} plan
            </Typography>
            <Box sx={{ display: "flex", gap: "15px" }}>
              <FormControl fullWidth>
                <Select
                  labelId="interval"
                  id="interval"
                  value={period}
                  label="Select Plan"
                  onChange={handlePlanChange}
                >
                  <MenuItem value="month-1">Billed Monthly</MenuItem>
                  <MenuItem value="year-1">Billed Annually</MenuItem>
                  <MenuItem value="year-2">Billed Every 2 Years</MenuItem>
                  <MenuItem value="year-3">Billed Every 3 Years</MenuItem>
                </Select>
              </FormControl>
              {period.toLowerCase() === "billed monthly" && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "600",
                    color: "#21a970",
                    textAlign: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => setPeriod("Billed annually")}
                >
                  Save $5
                  <br />
                  annually
                </Typography>
              )}
            </Box>
            {/* select agent Numbers */}
            <Box display="flex" alignItems="center" mb="20px">
              <TextField
                type="number"
                variant="outlined"
                defaultValue={1}
                inputProps={{ min: 1 }}
                // fullWidth
                value={agentsNumber}
                // onChange={(e) => setAgentsNumber(Number(e.target.value))}
                onChange={(e) => {
                  const value = Math.max(1, Number(e.target.value));
                  setAgentsNumber(value);
                }}
              />
              <Typography
                sx={{
                  marginLeft: "8px",
                }}
              >
                {`agent${agentsNumber > 1 ? "s" : ""}`}
              </Typography>
            </Box>
            <Typography
              sx={{
                lineHeight: "21px",
                letterSpacing: "0",
                fontSize: "14px",
                fontWeight: "400",
                fontStyle: "normal",
                color: "#0059e1",
                textAlign: "left",
                cursor: "pointer",
              }}
              onClick={() => setOpenFeatureModal(true)}
            >
              Change plan
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                position: "absolute",
                right: "33px",
              }}
            >
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                ${getPriceAccordingToPlanString} / month
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "#9b9b9b" }}>
                per agent
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BillingPortal;
