import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Modal,
  Typography,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "src/hooks/useAuth";
import CREATE_NEW_SUBSCRIPTION from "src/graphql/query/subscription/createNewSubscription";
import { IPrices } from "src/components/pages/PricingPlans/dataTypes";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import { useMutation, useLazyQuery } from "@apollo/client";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import UPGRADE_SUBSCRIPTION from "src/graphql/mutation/settings/upgradeSubscription";

const style = {
  position: "absolute",
  top: "47%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "500px",
  width: "100%",
  bgcolor: "background.paper",
  color: "black",
  borderRadius: "8px",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "1rem",
};

interface IPlan {
  name: string;
  productId: string;
  description: string;
  prices: IPrices[];
}

interface Props {
  upgradingPlanData?: IPlan;
  open: boolean;
  setOpen: (a: boolean) => void;
  plan: string;
}

const UpgradePlanModal: React.FC<Props> = ({
  open,
  setOpen,
  upgradingPlanData,
  plan,
}) => {
  const { subscriptionId, stripeId } = useActiveUserDetail();
  const { refetchAuthData } = useAuth() as any;

  const navigate = useNavigate();

  console.log("upgrading_plan__", plan);
  console.log("subscriptionId__", subscriptionId);
  console.log("upgradingPlanData__", upgradingPlanData);

  const handleClose = () => setOpen(false);
  const [label, setLabel] = useState("billed monthly");
  const [perPeriodName, setPerPeriodName] = useState("");

  const [calculatedPrice, setCalculatedPrice] = useState(0);

  const [period, setPeriod] = useState(
    JSON.stringify({ value: "month-1", label: "Billed Monthly" }),
  );
  const [selectedInterval, setSelectedInterval] = useState({
    interval: "month",
    intervalCount: 1,
  });

  useEffect(() => {
    console.log("data__periodlabel", period, label);
  }, [period, label]);

  useEffect(() => {
    setSelectedInterval({
      ...selectedInterval,
      interval: plan === "monthly" ? "month" : "year",
      intervalCount: 1,
    });
    //
    setPeriod(
      JSON.stringify({
        value: plan === "monthly" ? "month-1" : "year-1",
        label: plan === "monthly" ? "Billed Monthly" : "Billed Annually",
      }),
    );
    setLabel(plan === "monthly" ? "billed monthly" : "Billed Annually");
  }, [plan, upgradingPlanData]);

  const [agentsNumber, setAgentsNumber] = useState(1);

  const handlePlanChange = (e: any) => {
    const selectedPeriod = JSON.parse(e.target.value);
    setPeriod(e.target.value);
    setLabel(selectedPeriod.label);

    const interval = selectedPeriod.value.split("-")[0];
    const intervalCount = Number(selectedPeriod.value.split("-")[1]);

    setSelectedInterval({
      interval,
      intervalCount,
    });
  };

  const getPricesAccordingToPlan = (pricesList: IPrices[]) => {
    const interval = selectedInterval.interval || "month";
    const intervalCount = selectedInterval.intervalCount || 1;

    if (interval === "month") {
      const montlyPrice = pricesList.find((item) => item.interval === "month");

      if (montlyPrice) {
        return Math.ceil(montlyPrice.price / 100);
      }
    }
    console.log("pricesList__", pricesList);
    if (interval === "year") {
      const annualPrice = pricesList.find(
        (item) =>
          item.interval === "year" && item.intervalCount === intervalCount,
      );
      if (annualPrice) {
        return Math.ceil(annualPrice.price / 100 / (12 * intervalCount));
      }
    }

    return null;
  };

  const getPriceAccordingToPlanString = useMemo(
    // () => getPricesAccordingToPlan(upgradingPlanData.prices),
    () =>
      getPricesAccordingToPlan(
        upgradingPlanData ? upgradingPlanData.prices : [],
      ),
    [selectedInterval, plan, upgradingPlanData],
  );

  const calculatedPriceFunc = () => {
    const upgradingPlanDataPricesArr = upgradingPlanData
      ? [...upgradingPlanData.prices]
      : [];
    const packagePriceBasedOnIntervalAndCount =
      //   selectedProductDetail.price.find(
      // upgradingPlanDataPricesArr.prices.find(
      upgradingPlanDataPricesArr.find(
        (curr) =>
          curr.interval === selectedInterval.interval &&
          curr.intervalCount === selectedInterval.intervalCount,
      );

    if (packagePriceBasedOnIntervalAndCount) {
      return (packagePriceBasedOnIntervalAndCount.price / 100) * agentsNumber;
    }

    return 0;
  };

  const perPeriodNameFunc = () => {
    if (selectedInterval.interval === "month") return "mo";

    switch (selectedInterval.intervalCount) {
      case 1:
        return "yr";
        break;
      case 2:
        return "2yr";
        break;
      case 3:
        return "3yr";
        break;

      default:
        return "";
        break;
    }
  };

  useEffect(() => {
    setCalculatedPrice(calculatedPriceFunc());
    setPerPeriodName(perPeriodNameFunc());
  }, [selectedInterval, agentsNumber, upgradingPlanData]);

  const selectingPlans = [
    { value: "month-1", label: "Billed Monthly" },
    { value: "year-1", label: "Billed Annually" },
    { value: "year-2", label: "Billed Every 2 Years" },
    { value: "year-3", label: "Billed Every 3 Years" },
  ];

  const [
    upgradeSubscription,
    {
      loading: updateSubscriptionLoading,
      error: updateSubscriptionError,
      data: updateSubscriptionResult,
    },
  ] = useMutation(UPGRADE_SUBSCRIPTION);

  useEffect(() => {
    if (
      updateSubscriptionResult &&
      updateSubscriptionResult.updateSubscription
    ) {
      if (updateSubscriptionResult.updateSubscription) {
        console.log(
          "updateSubscriptionResult.updateSubscription__",
          updateSubscriptionResult.updateSubscription,
        );
        if (updateSubscriptionResult.updateSubscription.success) {
          setOpen(false);
          // recalling ME query
          refetchAuthData();
          enqueueSnackbar(
            `Plan has been successfully upgraded to ${upgradingPlanData?.name}`,
            {
              variant: "success",
            },
          );
          //
          setTimeout(() => {
            navigate("/settings/billing");
          }, 500);
        } else {
          enqueueSnackbar(updateSubscriptionResult.updateSubscription.error, {
            variant: "error",
          });
        }
      }
    }
  }, [updateSubscriptionResult]);

  useEffect(() => {
    if (updateSubscriptionError) {
      enqueueSnackbar(updateSubscriptionError.message, {
        variant: "error",
      });
    }
  }, [updateSubscriptionError]);

  const handleUpgradePlan = () => {
    const upgradingPlanDataPricesArr = upgradingPlanData
      ? [...upgradingPlanData.prices]
      : [];
    const packagePriceBasedOnIntervalAndCount = upgradingPlanDataPricesArr.find(
      (curr) =>
        curr.interval === selectedInterval.interval &&
        curr.intervalCount === selectedInterval.intervalCount,
    );

    upgradeSubscription({
      variables: {
        subscriptionId,
        newPlanId: packagePriceBasedOnIntervalAndCount?.priceId,
        newQuantity: agentsNumber,
        billingCycleInterval: packagePriceBasedOnIntervalAndCount?.interval,
        billingCycleIntervalCount:
          packagePriceBasedOnIntervalAndCount?.intervalCount,
      },
    });
  };

  const [
    createNewSubscription,
    {
      loading: createNewSubscriptionLoading,
      error: createNewSubscriptionError,
      data: createNewSubscriptionResult,
    },
  ] = useLazyQuery(CREATE_NEW_SUBSCRIPTION, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (createNewSubscriptionResult?.createNewSubscription?.success) {
      setOpen(false);
      // recalling ME query
      refetchAuthData();
      enqueueSnackbar(
        `Subscription has been created for ${upgradingPlanData?.name}`,
        {
          variant: "success",
        },
      );
      //
      setTimeout(() => {
        navigate("/settings/billing");
      }, 500);
    } else if (createNewSubscriptionResult?.createNewSubscription?.error) {
      enqueueSnackbar(createNewSubscriptionResult.createNewSubscription.error, {
        variant: "error",
      });
    }
  }, [createNewSubscriptionResult]);

  useEffect(() => {
    if (createNewSubscriptionError) {
      enqueueSnackbar(createNewSubscriptionError.message, {
        variant: "error",
      });
    }
  }, [createNewSubscriptionError]);

  const handleCreatePlan = async () => {
    const upgradingPlanDataPricesArr = upgradingPlanData
      ? [...upgradingPlanData.prices]
      : [];

    const packagePriceBasedOnIntervalAndCount = upgradingPlanDataPricesArr.find(
      (curr) =>
        curr.interval === selectedInterval.interval &&
        curr.intervalCount === selectedInterval.intervalCount,
    );

    await createNewSubscription({
      variables: {
        customerId: stripeId,
        priceId: packagePriceBasedOnIntervalAndCount?.priceId,
        noOfAgents: agentsNumber,
        planName: upgradingPlanData?.name,
      },
    });
    //
  };

  if (!upgradingPlanData)
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>Loading</Box>
      </Modal>
    );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Upgrade Plan
          </Typography>
          <IconButton onClick={handleClose} sx={{ color: "black" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {/* Empty Body */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
            {upgradingPlanData.name}
          </Typography>
          <Typography
            sx={{ fontSize: "14px" }}
            color="text.secondary"
            variant="body2"
          >
            {upgradingPlanData.description}
          </Typography>
          {/* show price per mon per agent */}
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            ${getPriceAccordingToPlanString} / month
          </Typography>
          {/* form part */}
          <Box
            sx={{
              width: "100%",
              dispaly: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Box sx={{ display: "flex", gap: "15px" }}>
              <FormControl fullWidth sx={{ flex: 1 }}>
                <Select
                  labelId="interval"
                  id="interval"
                  value={period}
                  label="Select Plan"
                  onChange={handlePlanChange}
                >
                  {selectingPlans.map((planItem) => (
                    <MenuItem
                      key={planItem.value}
                      value={JSON.stringify(planItem)}
                    >
                      {planItem.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box
                sx={{ flex: 1 }}
                display="flex"
                alignItems="center"
                mb="20px"
              >
                <TextField
                  type="number"
                  variant="outlined"
                  defaultValue={1}
                  inputProps={{ min: 1 }}
                  value={agentsNumber}
                  onChange={(e) => {
                    const value = Math.max(1, Number(e.target.value));
                    setAgentsNumber(value);
                  }}
                />
                <Typography
                  sx={{
                    marginLeft: "8px",
                  }}
                >
                  {`agent${agentsNumber > 1 ? "s" : ""}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Footer */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            borderTop: "1px solid #bfbcbc",
            paddingTop: "15px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                lineHeight: "1",
                alignSelf: "flex-start",
              }}
            >
              $
            </Typography>
            <Typography
              sx={{
                fontSize: "2.0rem",
                fontWeight: "bold",
                lineHeight: "1",
                marginLeft: "2px",
                marginRight: "4px",
              }}
            >
              {calculatedPrice}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "text.secondary",
              }}
            >
              /{perPeriodName} ({label})
            </Typography>
          </Box>

          <Button
            variant="contained"
            sx={{
              bgcolor: "#4d1277",
              color: "white",
              "&:hover": {
                bgcolor: "#4d1277",
                color: "white",
              },
            }}
            onClick={!subscriptionId ? handleCreatePlan : handleUpgradePlan}
          >
            Upgrade to {upgradingPlanData.name}{" "}
            {(updateSubscriptionLoading || createNewSubscriptionLoading) && (
              <CircularProgress
                sx={{ color: "white", marginLeft: "10px" }}
                size={15}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpgradePlanModal;
