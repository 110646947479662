import { gql } from "@apollo/client";

const CREATE_NEW_SUBSCRIPTION = gql`
  query createNewSubscription(
    $customerId: String!
    $priceId: String!
    $noOfAgents: Int!
    $planName: String!
  ) {
    createNewSubscription(
      customerId: $customerId
      priceId: $priceId
      noOfAgents: $noOfAgents
      planName: $planName
    ) {
      success
      error
      data {
        id
        status
        latest_invoice
      }
    }
  }
`;
export default CREATE_NEW_SUBSCRIPTION;
