import { gql } from "@apollo/client";

const GET_CURRENT_PAYMENT_METHODS = gql`
  query getCurrentPaymentMethods($stripeCustomerId: String!) {
    getCurrentPaymentMethods(stripeCustomerId: $stripeCustomerId) {
      success
      error
      result {
        defaultPaymentMethodId
        paymentMethodsList {
          id
          brand
          last4
          expiry
          isDefault
        }
      }
    }
  }
`;
export default GET_CURRENT_PAYMENT_METHODS;
