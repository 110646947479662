import { gql } from "@apollo/client";

const userFields = gql`
  fragment userFields on Me {
    id
    settings
    name
    designation {
      paneltype
    }
    pagesData
    pages

    switchaccountsettings
    onlineStatus
    mainSuperAdminId
    picture
    pseudonym
    number
    username
    status
    stripe_id
    plans
    hasTrial
    endDate
    accessPeriodEndDate
    alert
    subscriptionId
    invoice_url
    currentCount
    maxCount
    stripe_subscription_status
  }
`;

export default userFields;
