import * as React from "react";
import { Box, Modal, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import ADD_PAYMENT_METHOD_FOR_CUSTOMERS from "src/graphql/mutation/settings/paymentMethod/addPaymentMethod";
import { useMutation } from "@apollo/client";
import { enqueueSnackbar } from "notistack";
import { ICurrentSubscriptionData } from "./types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "background.paper",
  color: "black",
  borderRadius: "8px",
  boxShadow: 24,
  padding: "1.5rem",
};

const cardElementStyle = {
  style: {
    base: {
      fontSize: "16px",
      color: "#32325d",
      fontSmoothing: "antialiased",
      "::placeholder": { color: "#aab7c4" },
    },
    invalid: { color: "#fa755a" },
  },
};

interface Props {
  open: boolean;
  setOpen: (a: boolean) => void;
  stripeCustomerId: string;
  setCurrentSubscriptionData?: React.Dispatch<
    React.SetStateAction<ICurrentSubscriptionData | undefined>
  >;
  setOpenUpgradePopup?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddNewMethodModal: React.FC<Props> = ({
  open,
  setOpen,
  stripeCustomerId,
  setCurrentSubscriptionData,
  setOpenUpgradePopup,
}) => {
  const handleClose = () => setOpen(false);
  const stripe: any = useStripe();
  const elements = useElements();

  const [cardError, setCardError] = React.useState("");
  const [expiryError, setExpiryError] = React.useState("");
  const [cvcError, setCvcError] = React.useState("");
  const [isFormValid, setIsFormValid] = React.useState(false);

  const validateForm = () => {
    const isValid = !cardError && !expiryError && !cvcError;
    setIsFormValid(isValid);
  };

  const handleCardChange = (event: any) => {
    setCardError(event.error ? event.error.message : "");
    validateForm();
  };

  const handleExpiryChange = (event: any) => {
    setExpiryError(event.error ? event.error.message : "");
    validateForm();
  };

  const handleCvcChange = (event: any) => {
    setCvcError(event.error ? event.error.message : "");
    validateForm();
  };

  const [
    addPaymentMethodForCustomers,
    {
      loading: addPaymentMethodForCustomersLoading,
      error: addPaymentMethodForCustomersError,
      data: addPaymentMethodForCustomersResult,
    },
  ] = useMutation(ADD_PAYMENT_METHOD_FOR_CUSTOMERS);

  React.useEffect(() => {
    if (addPaymentMethodForCustomersResult?.addPaymentMethodForCustomer) {
      // Extract the new payment method
      const newPaymentMethod =
        addPaymentMethodForCustomersResult?.addPaymentMethodForCustomer
          ?.paymentMethod;

      if (newPaymentMethod) {
        // it works in the billing screen for adding in the payment method in the realtime
        // Update the current subscription data with the new payment method
        setCurrentSubscriptionData?.((prevData: any) => ({
          ...prevData,
          paymentMethods: [
            ...(prevData?.paymentMethods || []),
            newPaymentMethod,
          ],
        }));
        // it opens the upgrde plan modal after adding the payment method in the upgrade plan
        setOpenUpgradePopup?.(true);
      }
      //
      enqueueSnackbar(
        addPaymentMethodForCustomersResult.addPaymentMethodForCustomer.message,
        {
          variant: addPaymentMethodForCustomersResult
            ?.addPaymentMethodForCustomer?.success
            ? "success"
            : "error",
        },
      );
      handleClose();
    }
  }, [addPaymentMethodForCustomersResult]);

  React.useEffect(() => {
    if (addPaymentMethodForCustomersError) {
      enqueueSnackbar(addPaymentMethodForCustomersError.message, {
        variant: "error",
      });
    }
  }, [addPaymentMethodForCustomersError]);

  const handleAddPaymentMethod = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardNumberElement);

    // Create payment method
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    console.log(paymentMethod, "paymentMethod");

    if (error) {
      console.error(error.message);
    } else {
      await addPaymentMethodForCustomers({
        variables: {
          // eslint-disable-next-line object-shorthand
          stripeCustomerId: stripeCustomerId,
          paymentMethodId: paymentMethod.id,
        },
      });
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Add Payment Method
          </Typography>
          <IconButton onClick={handleClose} sx={{ color: "black" }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form onSubmit={handleAddPaymentMethod}>
          {/* Card Number */}
          <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
            Card Number
          </Typography>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "10px",
              mb: 2,
            }}
          >
            <CardNumberElement
              options={cardElementStyle}
              onChange={handleCardChange}
            />
          </Box>
          {cardError && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {cardError}
            </Typography>
          )}

          {/* Expiration Date & CVC */}
          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
            <Box sx={{ width: "50%" }}>
              <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
                Expiration Date
              </Typography>
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <CardExpiryElement
                  options={cardElementStyle}
                  onChange={handleExpiryChange}
                />
              </Box>
              {expiryError && (
                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                  {expiryError}
                </Typography>
              )}
            </Box>

            <Box sx={{ width: "50%" }}>
              <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
                Security Code
              </Typography>
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <CardCvcElement
                  options={cardElementStyle}
                  onChange={handleCvcChange}
                />
              </Box>
              {cvcError && (
                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                  {cvcError}
                </Typography>
              )}
            </Box>
          </Box>

          {/* Buttons */}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              sx={{ color: "black", borderColor: "#555" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#4d1277",
                color: "white",
                "&:hover": {
                  bgcolor: "#4d1277",
                  color: "white",
                },
              }}
              disabled={!stripe || !isFormValid}
            >
              Add Payment Method {addPaymentMethodForCustomersLoading && "..."}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddNewMethodModal;
