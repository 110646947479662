import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  cardBrand: string;
  cardExpiry: string;
  loader: boolean;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  onClose,
  onDelete,
  cardBrand,
  cardExpiry,
  loader,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.2rem" }}
      >
        Are you sure you want to delete this card?
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography variant="body2" color="text.secondary">
          This credit card will be deleted.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 1,
            bgcolor: "#F4F6F8",
            p: 2,
            borderRadius: 2,
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "5px",
            }}
          >
            <CreditCardIcon sx={{ color: "gray" }} />
            <Typography variant="body2" fontWeight="bold">
              Credit card
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {cardBrand} ending in {cardExpiry}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
        <Button onClick={onClose} variant="outlined" sx={{ flex: 1, mr: 1 }}>
          Cancel
        </Button>
        <Button
          onClick={onDelete}
          variant="contained"
          color="error"
          sx={{ flex: 1 }}
        >
          Delete{" "}
          {loader && (
            <CircularProgress
              sx={{ color: "white", marginLeft: "10px" }}
              size={15}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
