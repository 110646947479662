/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useTheme } from "@mui/material/styles";
import { Box, CssBaseline, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import Header from "src/components/layout/mainLayout/header";
import LeftNavigationList from "src/components/layout/mainLayout/navigationList";
import BottomNavigationList from "src/components/layout/mainLayout/bottomNavigation";
import { useState, useEffect, type FC, type ReactNode } from "react";
import Main from "src/components/layout/mainLayout/main/Main";
import useNetwork from "src/hooks/useNetwork";
import NullifiedAccessPeriodMessage from "./nullifiedAccessPeriodMsg";
import useStyles from "./Styles";

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const isOnline = useNetwork();
  const [prevIsOnline, setPrevIsOnline] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();

  useEffect((): any => {
    if (!isOnline) {
      enqueueSnackbar("Internet connection lost. Please check your network.", {
        variant: "error",
      });
    } else if (isOnline && prevIsOnline) {
      enqueueSnackbar("Internet connection recovered.", {
        variant: "success",
      });
    }
  }, [isOnline, prevIsOnline, enqueueSnackbar]);

  useEffect(() => {
    if (!isOnline) setPrevIsOnline(true);
  }, [isOnline]);

  return (
    <Box className={classes.box}>
      <CssBaseline />
      <NullifiedAccessPeriodMessage />
      <Grid className={classes.grid}>
        <LeftNavigationList />
      </Grid>
      <Grid className={classes.gridg}>
        <Grid className={classes.gridHeader}>
          {" "}
          <Header />
        </Grid>
        <Grid className={classes.gridMain}>
          <Main theme={theme}>
            <Box className={classes.mainBox}>{children}</Box>
          </Main>
        </Grid>
        <BottomNavigationList />
      </Grid>
    </Box>
  );
};

export default MainLayout;

// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable no-console */
// import { useCallback } from "react";
// import { useTheme } from "@mui/material/styles";
// import { Box, CssBaseline, Grid, Typography, Button } from "@mui/material";
// import { useSnackbar, closeSnackbar, SnackbarKey } from "notistack";
// import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
// import { useNavigate } from "react-router-dom";
// import Header from "src/components/layout/mainLayout/header";
// import LeftNavigationList from "src/components/layout/mainLayout/navigationList";
// import BottomNavigationList from "src/components/layout/mainLayout/bottomNavigation";
// import { useState, useEffect, type FC, type ReactNode } from "react";
// import Main from "src/components/layout/mainLayout/main/Main";
// import useNetwork from "src/hooks/useNetwork";
// import SETUSERALERT from "src/graphql/mutation/freeAccess/setUserAlert";
// import { useMutation } from "@apollo/client";
// import useStyles from "./Styles";

// interface MainLayoutProps {
//   children?: ReactNode;
// }

// const MainLayout: FC<MainLayoutProps> = ({ children }) => {
//   const navigate = useNavigate();

//   const theme = useTheme();
//   const isOnline = useNetwork();
//   const [prevIsOnline, setPrevIsOnline] = useState(false);
//   const { enqueueSnackbar } = useSnackbar();
//   const { classes } = useStyles();
//   const { alert, agentId } = useActiveUserDetail();

//   const [
//     setUserAlert,
//     {
//       // data: setUserAlertData,
//       error: setUserAlertError,
//     },
//   ] = useMutation(SETUSERALERT, {
//     fetchPolicy: "network-only",
//   });

//   useEffect(() => {
//     if (setUserAlertError) {
//       enqueueSnackbar(setUserAlertError.message, {
//         variant: "error",
//       });
//     }
//   }, [setUserAlertError]);

//   useEffect((): any => {
//     if (!isOnline) {
//       enqueueSnackbar("Internet connection lost. Please check your network.", {
//         variant: "error",
//       });
//     } else if (isOnline && prevIsOnline) {
//       enqueueSnackbar("Internet connection recovered.", {
//         variant: "success",
//       });
//     }
//   }, [isOnline, prevIsOnline, enqueueSnackbar]);

//   useEffect(() => {
//     if (!isOnline) setPrevIsOnline(true);
//   }, [isOnline]);

//   const snackbarAction = useCallback(
//     (key: SnackbarKey | undefined) => (
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//         }}
//       >
//         <Typography style={{ fontSize: "14px" }}>
//           Your FreeAccess plan has expired, leading to restrictions of features
//           in accordance with the subscription.
//         </Typography>
//         <Typography style={{ fontSize: "12px", margin: "10px 0" }}>
//           All subordinates have been blocked, which requires you to unblock
//           users of your choice.
//         </Typography>
//         <Button
//           sx={{
//             backgroundColor: "#4d1277",
//             color: "white",
//             fontFamily: "Poppins",
//             fontWeight: 500,
//             padding: "3px 5px",
//             "&:hover": {
//               backgroundColor: "#4d1277",
//             },
//             fontSize: "12px",
//           }}
//           onClick={() => {
//             closeSnackbar(key);
//             setUserAlert({
//               variables: {
//                 agentId,
//               },
//             });
//             navigate("/users");
//           }}
//         >
//           Go to Users Page
//         </Button>
//       </Box>
//     ),
//     [agentId], // Dependencies to prevent unnecessary recreation
//   );

//   useEffect(() => {
//     if (alert) {
//       enqueueSnackbar("Expiration of freeAccessPlan", {
//         variant: "info",
//         anchorOrigin: {
//           vertical: "top",
//           horizontal: "center",
//         },
//         autoHideDuration: null,
//         action: snackbarAction,
//       });
//     }
//   }, [alert, snackbarAction]);

//   return (
//     <Box className={classes.box}>
//       <CssBaseline />
//       <Grid className={classes.grid}>
//         <LeftNavigationList />
//       </Grid>
//       <Grid className={classes.gridg}>
//         <Grid className={classes.gridHeader}>
//           {" "}
//           <Header />
//         </Grid>
//         <Grid className={classes.gridMain}>
//           <Main theme={theme}>
//             <Box className={classes.mainBox}>{children}</Box>
//           </Main>
//         </Grid>
//         <BottomNavigationList />
//       </Grid>
//     </Box>
//   );
// };

// export default MainLayout;
