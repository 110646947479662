import { gql } from "@apollo/client";

const GET_CURRENT_SUBSCRIPTION = gql`
  query getCurrentSubscriptions(
    $subscriptionId: String!
    $stripeCustomerId: String!
  ) {
    getCurrentSubscriptions(
      subscriptionId: $subscriptionId
      stripeCustomerId: $stripeCustomerId
    ) {
      success
      error
      result {
        packageName
        price
        interval
        renewalDate
        currentUsersCount
        maxUsersCount
        paymentMethods {
          id
          brand
          last4
          expiry
          isDefault
        }
        invoices {
          id
          amount
          currency
          date
          status
          invoiceUrl
        }
      }
    }
  }
`;
export default GET_CURRENT_SUBSCRIPTION;
