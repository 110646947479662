import { gql } from "@apollo/client";

const REMOVE_PAYMENT_METHOD_FOR_CUSTOMERS = gql`
  mutation removePaymentMethodForCustomer($paymentMethodId: String!) {
    removePaymentMethodForCustomer(paymentMethodId: $paymentMethodId) {
      success
      message
    }
  }
`;

export default REMOVE_PAYMENT_METHOD_FOR_CUSTOMERS;
