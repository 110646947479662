/* eslint-disable no-nested-ternary */
import React from "react";
import { Button, ButtonGroup, Box } from "@mui/material";

interface Props {
  plan: string;
  setPlan: (a: string) => void;
  setting?: boolean;
}

const SlidingButton: React.FC<Props> = ({ plan, setPlan, setting = false }) => {
  const handleClick = (period: string) => {
    setPlan(period);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <ButtonGroup
        variant="contained"
        disableRipple
        sx={{
          boxShadow: "none",
          border: "1px solid #4d1277",
          borderRadius: "20px",
          overflow: "hidden",

          "& .MuiButton-root": {
            position: "relative",
            padding: "10px 20px",
            width: "15rem",
            transition: "background-color 0.3s ease",
          },
        }}
      >
        <Button
          sx={{
            backgroundColor:
              plan === "monthly" ? "#4d1277" : setting ? "white" : "#e6d9ec",
            color: plan === "monthly" ? "white" : "black",
            "&:hover": {
              backgroundColor:
                plan === "monthly" ? "#4d1277" : setting ? "white" : "#e6d9ec",
            },
          }}
          onClick={() => handleClick("monthly")}
        >
          Monthly
        </Button>
        <Button
          sx={{
            backgroundColor:
              plan === "annual" ? "#4d1277" : setting ? "white" : "#e6d9ec",
            color: plan !== "monthly" ? "white" : "black",
            "&:hover": {
              backgroundColor:
                plan === "annual" ? "#4d1277" : setting ? "white" : "#e6d9ec",
            },
          }}
          onClick={() => handleClick("annual")}
        >
          Annual
          <span style={{ color: plan === "monthly" ? "green" : "white" }}>
            {" "}
            (Save up to 17%)
          </span>
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default SlidingButton;
