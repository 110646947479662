/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Backdrop,
  IconButton,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { enqueueSnackbar } from "notistack";
import LogoutQuery from "src/graphql/query/auth/logout";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import GetPackagesDetail from "src/graphql/query/pricingplan/GetPackagesDetail";
import CREATE_SUBSCRIPTION_INTENT from "src/graphql/query/subscription/createSubscriptionIntent";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import PlanSelector from "./PeriodSelectorSlider";
import { IPrices, IPlan, ISelectedProductDetails } from "./dataTypes";
import FeatureModal from "./FeaturesModal";
import SubscriptionPlan from "./SubscriptionPlan";
import PlanPurchaseModal from "./PlanPurchaseModal";
// import PlanSelector from "./planSelector";
import BillingPortal from "./BillingPortal";
import BillingInformation from "./BillingInfo";
import useStyles from "./Styles";
import Summary from "./Summary";

interface Props {
  settingsPage?: boolean;
  settingsOnClick?: () => void;
  update?: boolean;
  setShowScreen?: any;
  refetch?: any;
}

const Pricing: React.FC<Props> = ({
  settingsPage,
  settingsOnClick,
  update,
  setShowScreen,
  refetch,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const formRef = useRef<any>(null);

  const { hasTrial, plans, stripeId } = useActiveUserDetail();

  const [step, setStep] = useState<Number>(1);

  const [calculatedCutPrice, setCalculatedCutPrice] = useState(0);
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  const [perPeriodName, setPerPeriodName] = useState("");
  const [agentsNumber, setAgentsNumber] = useState(1);
  const [priceId, setPriceId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [products, setProducts] = useState<IPlan[]>([]);
  const [plan, setPlan] = useState("annual");

  const [paymentProcessingLoader, setPaymentProcessingLoader] = useState(false);

  // Todo => selectedProductDetail
  const [productDetail, setProductDetail] = useState<ISelectedProductDetails>({
    productName: "",
    productId: "",
    interval: "month",
    price: [],
  });

  const [openFeatureModal, setOpenFeatureModal] = React.useState(false);

  const [openPlanSuccessPurchaseModal, setOpenPlanSuccessPurchaseModal] =
    React.useState(false);

  // used for logout
  const [loading, setLoading] = useState(false);

  const [
    createSubscriptionIntent,
    {
      loading: createSubscriptionIntentLoading,
      error: createSubscriptionIntentError,
      data: createSubscriptionIntentResult,
    },
  ] = useLazyQuery(CREATE_SUBSCRIPTION_INTENT, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (createSubscriptionIntentResult?.createSubscriptionIntent?.success) {
      setClientSecret(
        createSubscriptionIntentResult.createSubscriptionIntent.data
          .clientSecret,
      );
    } else if (
      createSubscriptionIntentResult?.createSubscriptionIntent?.error
    ) {
      enqueueSnackbar(
        createSubscriptionIntentResult.createSubscriptionIntent.error,
        {
          variant: "error",
        },
      );
    }
  }, [createSubscriptionIntentResult]);

  useEffect(() => {
    if (createSubscriptionIntentError) {
      enqueueSnackbar(createSubscriptionIntentError.message, {
        variant: "error",
      });
    }
  }, [createSubscriptionIntentError]);

  const {
    loading: getPackagesDetailQueryLoading,
    error: getPackagesDetailQueryError,
    data: getPackagesDetailQueryResult,
  } = useQuery(GetPackagesDetail, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (getPackagesDetailQueryResult?.getPackagesDetails?.success) {
      if (getPackagesDetailQueryResult?.getPackagesDetails?.data) {
        const order = ["Basic", "Standard", "Business"];
        const orderedResult =
          getPackagesDetailQueryResult.getPackagesDetails.data.sort(
            (a: IPlan, b: IPlan) =>
              order.indexOf(a.name) - order.indexOf(b.name),
          );
        setProducts(orderedResult);
      }
    } else if (getPackagesDetailQueryResult?.getPackagesDetails?.error) {
      enqueueSnackbar(getPackagesDetailQueryResult.getPackagesDetails.error, {
        variant: "error",
      });
    }
  }, [getPackagesDetailQueryResult]);
  useEffect(() => {
    if (getPackagesDetailQueryError) {
      enqueueSnackbar(getPackagesDetailQueryError.message, {
        variant: "error",
      });
    }
  }, [getPackagesDetailQueryError]);

  const [logout, { data: logoutQueryResult, loading: logoutQueryLoading }] =
    useMutation(LogoutQuery);

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (logoutQueryResult && logoutQueryResult.logout.success) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("ActiveUserdetail");
      localStorage.removeItem("userName");
      navigate("login");
      setLoading(false);
    }
  }, [logoutQueryResult]);

  useEffect(() => {
    if (logoutQueryLoading) setLoading(true);
  }, [logoutQueryLoading]);

  const handlePackagePurchase = async (
    prices: IPrices[],
    selectedProductName: string,
    productId: string,
  ) => {
    setProductDetail({
      productName: selectedProductName,
      productId,
      interval: plan,
      price: prices,
    });

    setStep(2);
  };

  const handleSummaryHandler = async () => {
    if (step === 2) {
      // Call create Subscription Intent here
      await createSubscriptionIntent({
        variables: {
          customerId: stripeId,
          // eslint-disable-next-line object-shorthand
          priceId: priceId,
          noOfAgents: agentsNumber,
          planName: productDetail.productName,
        },
      });
      setStep(3);
      return;
    }

    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true }),
      );
    }
  };
  return (
    <>
      <FeatureModal
        open={openFeatureModal}
        setOpen={setOpenFeatureModal}
        loader={getPackagesDetailQueryLoading}
        products={products}
        handlePackagePurchase={handlePackagePurchase}
        plan={plan}
        setPlan={setPlan}
      />

      <PlanPurchaseModal
        open={openPlanSuccessPurchaseModal}
        setOpen={setOpenPlanSuccessPurchaseModal}
        amount={23400}
        planName="Business"
        onDashboardClick={() => {}}
        onReceiptClick={() => {}}
      />

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="primary" />
        <Typography color="primary">Logging out</Typography>
      </Backdrop>

      <Box className={classes.main_container}>
        <Box className={classes.pricing_top}>
          {step === 1 ? (
            <Typography className={classes.subscription_text}>
              {update ? "Update Subscription" : "Buy Package"}
            </Typography>
          ) : (
            <IconButton
              onClick={() => setStep((val: any) => val - 1)}
              className={classes.subscription_text}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          {!settingsPage ? (
            <Button
              className={classes.subscription_btn}
              onClick={() => handleLogout()}
            >
              Log out
            </Button>
          ) : (
            <IconButton
              onClick={() => settingsOnClick && settingsOnClick()}
              className={classes.subscription_text}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
        </Box>

        {step === 1 && (
          <Box
            className={clsx(classes.pricing_container, {
              [classes.settingsWidth]: settingsPage,
            })}
          >
            {hasTrial === "1" && (!plans || plans === "null") && (
              <Box className={classes.subscribe_text}>
                <DoNotDisturbIcon className={classes.subscribe_icon} />
                <Typography>
                  Your trial has expired. To restore your account,{" "}
                  <span style={{ color: "#3168e1" }}>subscribe now</span>
                </Typography>
              </Box>
            )}
            <PlanSelector plan={plan} setPlan={setPlan} />
            <Box className={classes.plan_container}>
              {getPackagesDetailQueryLoading ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                products.map((item: IPlan, index: number) => (
                  <SubscriptionPlan
                    {...item}
                    keyIndex={index}
                    plan={plan}
                    handleOnClick={() =>
                      handlePackagePurchase(
                        item.prices,
                        item.name,
                        item.productId,
                      )
                    }
                    packageDetailsLoader={getPackagesDetailQueryLoading}
                  />
                ))
              )}
            </Box>
          </Box>
        )}
        {step === 1 && (
          <Typography
            className={classes.feature_text}
            onClick={() => setOpenFeatureModal(true)}
          >
            See all features
          </Typography>
        )}

        {step === 2 || step === 3 ? (
          <div className={classes.flexContainer}>
            {step === 2 && (
              <BillingPortal
                selectedProductDetail={productDetail}
                setCalculatedCutPrice={setCalculatedCutPrice}
                setCalculatedPrice={setCalculatedPrice}
                setPerPeriodName={setPerPeriodName}
                agentsNumber={agentsNumber}
                setPriceId={setPriceId}
                setAgentsNumber={setAgentsNumber}
                plan={plan}
                loader={getPackagesDetailQueryLoading}
                products={products}
                handlePackagePurchase={handlePackagePurchase}
                setPlan={setPlan}
              />
            )}
            {step === 3 && (
              <BillingInformation
                productName={productDetail.productName}
                productDetail={productDetail}
                settingsPage={settingsPage}
                setShowScreen={setShowScreen}
                refetch={refetch}
                formRef={formRef}
                clientSecret={clientSecret}
                setLoader={setPaymentProcessingLoader}
              />
            )}

            <Summary
              productName={productDetail.productName}
              cutPrice={calculatedCutPrice}
              calculatedPrice={calculatedPrice}
              perPeriod={perPeriodName}
              agentsNumber={agentsNumber}
              loading={
                createSubscriptionIntentLoading || paymentProcessingLoader
              }
              savingAmount={Number(
                (
                  ((calculatedCutPrice - calculatedPrice) /
                    calculatedCutPrice) *
                  100
                ).toFixed(2),
              )}
              btnText={step === 2 ? "Go to checkout" : "Subscribe Now"}
              onSubmit={handleSummaryHandler}
            />
          </div>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default Pricing;
