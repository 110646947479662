import { gql } from "@apollo/client";

const GET_PACKAGES_DETAILS = gql`
  query GetPackagesDetails {
    getPackagesDetails {
      success
      error
      data {
        name
        prices {
          interval
          price
          priceId
          intervalCount
          currency
        }
        productId
      }
    }
  }
`;

export default GET_PACKAGES_DETAILS;
