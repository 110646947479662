import React, { useEffect, useState } from "react";
import PlanSelector from "src/components/pages/PricingPlans/PeriodSelectorSlider";
import SubscriptionPlan from "src/components/pages/PricingPlans/SubscriptionPlan";
import GetPackagesDetail from "src/graphql/query/pricingplan/GetPackagesDetail";
import GET_CURRENT_PAYMENT_METHODS from "src/graphql/query/settings/getCurrentPaymentMethods";
import { Elements } from "@stripe/react-stripe-js";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import { loadStripe } from "@stripe/stripe-js";
import expressConfig from "src/config/express.json";
import { enqueueSnackbar } from "notistack";

import { Box, CircularProgress } from "@mui/material";
import { useQuery, useLazyQuery } from "@apollo/client";
import AddNewMethodModal from "../Billing/AddNewMethodModal";

import useStyle from "./Style";
import UpgradePlanModal from "./UpgradePlansModal";

interface IPrices {
  currency: string;
  interval: string;
  intervalCount: number;
  price: number;
  priceId: string;
}

interface IPlan {
  name: string;
  productId: string;
  description: string;
  prices: IPrices[];
}

const UpgradePlan = () => {
  const { classes } = useStyle();

  const [plan, setPlan] = useState("annual");
  const [products, setProducts] = useState<IPlan[]>([]);
  const [openUpgradePopup, setOpenUpgradePopup] = useState(false);
  const [upgradingPlanData, setUpgradingPlanData] = useState<IPlan>();

  const { stripeId: customerId, subscriptionId } = useActiveUserDetail();
  console.log("subscriptionId__", subscriptionId);

  const env = process.env.NODE_ENV || "development";
  const stripePublishableKey = expressConfig[env].STRIPE_PUB_KEY;
  const stripePromise = loadStripe(stripePublishableKey);

  const [openAddNewPaymentMethodModal, setOpenAddNewPaymentMethodModal] =
    useState(false);

  const {
    loading: getPackagesDetailQueryLoading,
    error: getPackagesDetailQueryError,
    data: getPackagesDetailQueryResult,
  } = useQuery(GetPackagesDetail, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (getPackagesDetailQueryResult?.getPackagesDetails?.success) {
      if (getPackagesDetailQueryResult?.getPackagesDetails?.data) {
        const order = ["Basic", "Standard", "Business"];
        const orderedResult =
          getPackagesDetailQueryResult.getPackagesDetails.data.sort(
            (a: IPlan, b: IPlan) =>
              order.indexOf(a.name) - order.indexOf(b.name),
          );
        setProducts(orderedResult);
      }
    } else if (getPackagesDetailQueryResult?.getPackagesDetails?.error) {
      enqueueSnackbar(getPackagesDetailQueryResult.getPackagesDetails.error, {
        variant: "error",
      });
    }
  }, [getPackagesDetailQueryResult]);
  useEffect(() => {
    if (getPackagesDetailQueryError) {
      enqueueSnackbar(getPackagesDetailQueryError.message, {
        variant: "error",
      });
    }
  }, [getPackagesDetailQueryError]);

  // const handleUpgradePlan = (item: IPlan) => {
  //   console.log("upgrade__item_", item);
  //   setOpenUpgradePopup(true);
  //   setUpgradingPlanData(item);
  // };

  const [
    getCurrentPaymentMethods,
    {
      // loading: getCurrentPaymentMethodsLoading,
      error: getCurrentPaymentMethodsError,
      data: getCurrentPaymentMethodsResult,
    },
  ] = useLazyQuery(GET_CURRENT_PAYMENT_METHODS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (
      getCurrentPaymentMethodsResult &&
      getCurrentPaymentMethodsResult.getCurrentPaymentMethods
    ) {
      if (getCurrentPaymentMethodsResult.getCurrentPaymentMethods) {
        const { success, result } =
          getCurrentPaymentMethodsResult.getCurrentPaymentMethods;
        if (
          success &&
          result.defaultPaymentMethodId &&
          result.paymentMethodsList.length > 0
        ) {
          // open upgrade plan modal
          setOpenUpgradePopup(true);
        } else {
          // open add payment modal
          setOpenAddNewPaymentMethodModal(true);
        }
        console.log(
          "current_paymetn_methods__",
          getCurrentPaymentMethodsResult.getCurrentPaymentMethods,
        );
      }
    }
  }, [getCurrentPaymentMethodsResult]);

  useEffect(() => {
    if (getCurrentPaymentMethodsError) {
      enqueueSnackbar(getCurrentPaymentMethodsError.message, {
        variant: "error",
      });
    }
  }, [getCurrentPaymentMethodsError]);

  const handleUpgradePlanChecking = (item: IPlan) => {
    getCurrentPaymentMethods({
      variables: {
        stripeCustomerId: customerId,
      },
    });
    setUpgradingPlanData(item);
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <AddNewMethodModal
          open={openAddNewPaymentMethodModal}
          setOpen={setOpenAddNewPaymentMethodModal}
          stripeCustomerId={customerId}
          setOpenUpgradePopup={setOpenUpgradePopup}
        />
      </Elements>
      <UpgradePlanModal
        plan={plan}
        upgradingPlanData={upgradingPlanData}
        open={openUpgradePopup}
        setOpen={setOpenUpgradePopup}
      />
      <div className={classes.Root}>
        <h3>Upgrade Plan</h3>
        <p>Kuikwit pricing plans are designed to meet your needs as you grow</p>
        <PlanSelector plan={plan} setPlan={setPlan} setting />
        <Box className={classes.plan_container}>
          {getPackagesDetailQueryLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          ) : (
            products.map((item: IPlan, index: number) => (
              <SubscriptionPlan
                setting
                {...item}
                keyIndex={index}
                plan={plan}
                // handleOnClick={() => handleUpgradePlan(item)}
                handleOnClick={() => handleUpgradePlanChecking(item)}
                packageDetailsLoader={getPackagesDetailQueryLoading}
              />
            ))
          )}
        </Box>
      </div>
    </>
  );
};

export default UpgradePlan;
