/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { Table, TableBody } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import DISCONNECT_PAGE from "src/graphql/mutation/settings/disconnectPage";
import CONNECT_PAGE from "src/graphql/mutation/settings/connectPage";
import DELETE_PAGE from "src/graphql/mutation/settings/deletePage";
import removeIcon from "src/assets/images/settings/close-02.svg";
import AddTaskIcon from "@mui/icons-material/AddTask";
import KuikwitDialog from "src/components/shared/atoms/dialog";
import InternalRow from "../../facebook/lowerComponent/row";
import useStyle from "../../facebook/lowerComponent/Styles";
import UpgradeModal from "../../upgradeModal";

interface Props {
  pagesData: any;
  setPagesData: (value: any) => void;
}

const InstagramInternalPage: React.FC<Props> = ({
  pagesData,
  setPagesData,
}) => {
  const { classes } = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [removeModal, setRemoveModal] = useState(false);
  const closeDeleteDialog = () => setRemoveModal(false);
  const [disconnectModal, setDisconnectModal] = useState(false);
  const [connectModal, setConnectModal] = useState(false);
  const closeRemoveDialog = () => setDisconnectModal(false);
  const closeConnectDialog = () => setConnectModal(false);
  const [actionPageData, setActionPageData] = useState();
  const [
    disconnectPage,
    {
      loading: disConnectPageMutationLoading,
      data: disConnectPageMutationResult,
    },
  ] = useMutation(DISCONNECT_PAGE);

  useEffect(() => {
    if (
      disConnectPageMutationResult &&
      disConnectPageMutationResult.disconnectPage
    ) {
      if (disConnectPageMutationResult.disconnectPage.success) {
        const updatedPagesData =
          pagesData && pagesData.length
            ? pagesData.map((element: { pageId: undefined }) => {
                if (element.pageId === actionPageData)
                  return {
                    ...element,
                    isSelected: false,
                  };
                return element;
              })
            : [];

        setPagesData(updatedPagesData);

        enqueueSnackbar(
          JSON.parse(disConnectPageMutationResult.disconnectPage.result),
          {
            variant: "info",
          },
        );
      } else {
        enqueueSnackbar(
          JSON.parse(disConnectPageMutationResult.disconnectPage.error),
          {
            variant: "error",
          },
        );
      }
      closeRemoveDialog();
    }
  }, [disConnectPageMutationResult]);

  const [
    connectPage,
    { loading: connectPageMutationLoading, data: connectPageMutationResult },
  ] = useMutation(CONNECT_PAGE);
  useEffect(() => {
    if (connectPageMutationResult && connectPageMutationResult.connectPage) {
      if (connectPageMutationResult.connectPage.success) {
        const updatedPagesData =
          pagesData && pagesData.length
            ? pagesData.map((element: { pageId: undefined }) => {
                if (element.pageId === actionPageData) {
                  return {
                    ...element,
                    isSelected: true,
                  };
                }
                return element;
              })
            : [];
        setPagesData(updatedPagesData);

        enqueueSnackbar(
          JSON.parse(connectPageMutationResult.connectPage.result),
          {
            variant: "info",
          },
        );
      } else {
        enqueueSnackbar(
          JSON.parse(connectPageMutationResult.connectPage.error),
          {
            variant: "error",
          },
        );
      }
      closeConnectDialog();
    }
  }, [connectPageMutationResult]);

  const [
    deletepage,
    { data: deletepageMutationResult, loading: deletepageMutationLoading },
  ] = useMutation(DELETE_PAGE);

  useEffect(() => {
    if (deletepageMutationResult && deletepageMutationResult.deletepage) {
      if (deletepageMutationResult.deletepage.success) {
        const pagesDataWithoutCurrData: any =
          pagesData &&
          pagesData.length &&
          pagesData.filter((curr: any) => curr.pageId !== actionPageData);
        setPagesData(pagesDataWithoutCurrData);
        enqueueSnackbar("page has been removed", { variant: "success" });
      } else {
        enqueueSnackbar(JSON.parse(deletepageMutationResult.deletepage.error), {
          variant: "error",
        });
      }
      closeDeleteDialog();
    }
  }, [deletepageMutationResult]);

  const disConnectApiCallHandler = async () => {
    try {
      await disconnectPage({
        variables: {
          pageId: actionPageData,
        },
      });
    } catch (err: any) {
      enqueueSnackbar("Something went wrong. Try again", {
        variant: "error",
      });
    }
  };
  const removeApiCallHandler = async () => {
    try {
      await deletepage({
        variables: {
          pageId: actionPageData,
        },
      });
    } catch (err: any) {
      enqueueSnackbar("Something went wrong. Try again", {
        variant: "error",
      });
    }
  };
  const connectApiCallHandler = async () => {
    try {
      await connectPage({
        variables: {
          pageId: actionPageData,
        },
      });
    } catch (err: any) {
      enqueueSnackbar("Something went wrong. Try again", {
        variant: "error",
      });
    }
  };

  const disconnectedHandler = (data: any) => {
    setDisconnectModal(true);
    setActionPageData(data.pageId);
  };

  const closeHandler = (data: any) => {
    setRemoveModal(true);
    setActionPageData(data.pageId);
  };

  const connectedHandler = (data: any) => {
    setConnectModal(true);
    setActionPageData(data.pageId);
  };

  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);

  const showUpgradePopUp = () => {
    setOpenUpgradeModal(true);
  };

  return (
    <>
      <UpgradeModal open={openUpgradeModal} setOpen={setOpenUpgradeModal} />
      <CardContent className={classes.cardContent}>
        <Box>
          <Table
            sx={{
              minWidth: 500,
            }}
          >
            <TableBody>
              {pagesData.map((curr: any) => {
                return (
                  <InternalRow
                    loading={
                      (connectPageMutationLoading ||
                        disConnectPageMutationLoading) &&
                      actionPageData === curr.pageId
                    }
                    disabled={
                      (connectPageMutationLoading ||
                        disConnectPageMutationLoading) &&
                      actionPageData === curr.pageId
                    }
                    connectedHandler={connectedHandler}
                    disconnectedHandler={disconnectedHandler}
                    showUpgradePopUp={showUpgradePopUp}
                    curr={curr}
                    closeHandler={closeHandler}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </CardContent>
      {removeModal && (
        <KuikwitDialog
          btnText="Remove"
          img={removeIcon}
          modalOpen={removeModal}
          removeItem={removeApiCallHandler}
          text="Do you really want to remove this page?"
          modalClose={closeDeleteDialog}
          loader={deletepageMutationLoading}
        />
      )}

      {disconnectModal && (
        <KuikwitDialog
          btnText="Disconnect"
          img={removeIcon}
          modalOpen={disconnectModal}
          removeItem={disConnectApiCallHandler}
          text="Do you really want to disconnect this page?"
          modalClose={closeRemoveDialog}
          loader={disConnectPageMutationLoading}
        />
      )}
      {connectModal && (
        <KuikwitDialog
          btnText="Connect Page"
          icon={<AddTaskIcon color="success" />}
          modalOpen={connectModal}
          removeItem={connectApiCallHandler}
          text="Do you want to connect this page?"
          modalClose={closeConnectDialog}
          loader={connectPageMutationLoading}
        />
      )}
    </>
  );
};

export default InstagramInternalPage;
