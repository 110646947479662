import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Chip,
  LinearProgress,
  CircularProgress,
  Button,
} from "@mui/material";
import UPDATE_DEFAULT_PAYMENT_METHOD_FOR_CUSTOMERS from "src/graphql/mutation/settings/paymentMethod/updatePaymentMethod";
import REMOVE_PAYMENT_METHOD_FOR_CUSTOMERS from "src/graphql/mutation/settings/paymentMethod/removePaymentMethod";
import GET_CURRENT_SUBSCRIPTION from "src/graphql/query/settings/getCurrentSubscriptions";
import CANCEL_SUBSCRIPTION from "src/graphql/mutation/settings/cancelSubscription";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { useMutation, useLazyQuery } from "@apollo/client";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import { enqueueSnackbar } from "notistack";
import AddIcon from "@mui/icons-material/Add";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import expressConfig from "src/config/express.json";
import { useSelector } from "react-redux";
import PaymentCard from "./paymentCard";
import DeleteConfirmationDialog from "./deletePaymentCardDailog";
import CancelSubscriptionConfirmationDialog from "./cancelSubscriptionModal";
import BillingHistory from "./billingHistory";
import AddNewMethodModal from "./AddNewMethodModal";
import {
  ICurrentSubscriptionData,
  IInvoiceArrayObject,
  IInvoice,
  IPaymentMethod,
} from "./types";

const env = process.env.NODE_ENV || "development";
const stripePublishableKey = expressConfig[env].STRIPE_PUB_KEY;
const stripePromise = loadStripe(stripePublishableKey);

const Billing = () => {
  //   const { classes } = useStyle();
  const { refetchAuthData } = useAuth() as any;

  const navigate = useNavigate();

  const {
    stripeId: customerId,
    subscriptionId,
    plans,
    endDate,
    currentCount,
    maxCount,
  } = useActiveUserDetail();

  const { me }: any = useSelector((state: any) => state.me);

  const [openAddNewPaymentMethodModal, setOpenAddNewPaymentMethodModal] =
    useState(false);

  const [currentSubscriptionData, setCurrentSubscriptionData] =
    useState<ICurrentSubscriptionData>();

  const [openDialog, setOpenDialog] = useState(false);
  const [openCancelSubDialog, setOpenCancelSubDialog] = useState(false);
  const [cardBrand, setCardBrand] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [toBeDeletePaymentId, setToBeDeletePaymentId] = useState("");
  const [toBeDefaultPaymentId, setToBeDefaultPaymentId] = useState("");
  const [inVoiceDataArray, setInVoiceDataArray] = useState<
    IInvoiceArrayObject[]
  >([]);

  const [
    getCurrentSubscription,
    {
      // loading: getCurrentSubscriptionLoading,
      error: getCurrentSubscriptionError,
      data: getCurrentSubscriptionResult,
    },
  ] = useLazyQuery(GET_CURRENT_SUBSCRIPTION, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (me && me.plans !== "freetrial" && me.subscriptionId)
      getCurrentSubscription({
        variables: {
          subscriptionId: me.subscriptionId,
          stripeCustomerId: customerId,
        },
      });
  }, [me]);

  useEffect(() => {
    if (
      getCurrentSubscriptionResult &&
      getCurrentSubscriptionResult.getCurrentSubscriptions
    ) {
      setCurrentSubscriptionData(
        getCurrentSubscriptionResult.getCurrentSubscriptions.result,
      );
      const updated =
        getCurrentSubscriptionResult.getCurrentSubscriptions.result.invoices.map(
          (item: IInvoice, index: number) => ({
            ...item,
            id: index + 1,
            invoice: item.id,
          }),
        );
      setInVoiceDataArray([...updated]);
    }
  }, [getCurrentSubscriptionResult]);

  useEffect(() => {
    if (getCurrentSubscriptionError) {
      enqueueSnackbar(getCurrentSubscriptionError.message, {
        variant: "error",
      });
    }
  }, [getCurrentSubscriptionError]);

  // update payment method work
  const [
    updateDefaultPaymentMethodForCustomers,
    {
      //   loading: updateDefaultPaymentMethodForCustomersLoading,
      error: updateDefaultPaymentMethodForCustomersError,
      data: updateDefaultPaymentMethodForCustomersResult,
    },
  ] = useMutation(UPDATE_DEFAULT_PAYMENT_METHOD_FOR_CUSTOMERS);

  useEffect(() => {
    if (
      updateDefaultPaymentMethodForCustomersResult &&
      updateDefaultPaymentMethodForCustomersResult.updateDefaultPaymentMethodForCustomer
    ) {
      if (
        updateDefaultPaymentMethodForCustomersResult.updateDefaultPaymentMethodForCustomer &&
        currentSubscriptionData
      ) {
        const updatingPaymentMethodArray =
          currentSubscriptionData.paymentMethods;
        const updatedPayment = updatingPaymentMethodArray.find(
          (pm) => pm.id === toBeDefaultPaymentId,
        );

        if (!updatedPayment) return; // If not found, return original array

        // Update the found object to isDefault: true
        updatedPayment.isDefault = true;

        // Update all other objects to isDefault: false
        const updatedList = updatingPaymentMethodArray.map((pm) =>
          pm.id === toBeDefaultPaymentId
            ? updatedPayment
            : { ...pm, isDefault: false },
        );

        // Move the newly set default object to the top
        const updatedPaymentMethodArray = [
          updatedPayment,
          ...updatedList.filter((pm) => pm.id !== toBeDefaultPaymentId),
        ];

        setCurrentSubscriptionData({
          ...currentSubscriptionData,
          paymentMethods: updatedPaymentMethodArray,
        });
      }
    }
  }, [updateDefaultPaymentMethodForCustomersResult]);

  useEffect(() => {
    if (updateDefaultPaymentMethodForCustomersError) {
      enqueueSnackbar(updateDefaultPaymentMethodForCustomersError.message, {
        variant: "error",
      });
    }
  }, [updateDefaultPaymentMethodForCustomersError]);

  // remove payment method work

  const [
    removePaymentMethodForCustomers,
    {
      loading: removePaymentMethodForCustomersLoading,
      error: removePaymentMethodForCustomersError,
      data: removePaymentMethodForCustomersResult,
    },
  ] = useMutation(REMOVE_PAYMENT_METHOD_FOR_CUSTOMERS);

  useEffect(() => {
    if (
      removePaymentMethodForCustomersResult &&
      removePaymentMethodForCustomersResult.removePaymentMethodForCustomer
    ) {
      if (
        removePaymentMethodForCustomersResult.removePaymentMethodForCustomer &&
        currentSubscriptionData
      ) {
        setOpenDialog(false);

        const paymentMethodsList = currentSubscriptionData?.paymentMethods;
        const updatedPaymentMethodsList = paymentMethodsList?.filter(
          (item) => item.id !== toBeDeletePaymentId,
        );

        setCurrentSubscriptionData({
          ...currentSubscriptionData,
          paymentMethods: updatedPaymentMethodsList,
        });

        enqueueSnackbar("Payment method has been deleted", {
          variant: "success",
        });
      }
    }
  }, [removePaymentMethodForCustomersResult]);

  useEffect(() => {
    if (removePaymentMethodForCustomersError) {
      enqueueSnackbar(removePaymentMethodForCustomersError.message, {
        variant: "error",
      });
    }
  }, [removePaymentMethodForCustomersError]);

  // adding payment method work

  const handleDelete = (paymentId: string, brand: string, expiry: string) => {
    setOpenDialog(true);
    setCardBrand(brand);
    setCardExpiry(expiry);
    setToBeDeletePaymentId(paymentId);
  };

  const handleDeletePayment = () => {
    removePaymentMethodForCustomers({
      variables: {
        paymentMethodId: toBeDeletePaymentId,
      },
    });
  };

  const handleSetDefault = (paymentMethodId: string) => {
    setToBeDefaultPaymentId(paymentMethodId);
    updateDefaultPaymentMethodForCustomers({
      variables: {
        customerId,
        paymentMethodId,
      },
    });
  };

  const handleAddPayment = () => {
    setOpenAddNewPaymentMethodModal(true);
  };

  // update payment method work
  const [
    cancelSubscription,
    {
      loading: cancelSubscriptionLoading,
      error: cancelSubscriptionError,
      data: cancelSubscriptionResult,
    },
  ] = useMutation(CANCEL_SUBSCRIPTION);

  useEffect(() => {
    if (
      cancelSubscriptionResult &&
      cancelSubscriptionResult.cancelSubscription
    ) {
      if (cancelSubscriptionResult.cancelSubscription) {
        if (cancelSubscriptionResult.cancelSubscription.success) {
          // recalling ME query
          refetchAuthData();
          enqueueSnackbar("Subscription has been successfully deleted", {
            variant: "success",
          });
          setOpenCancelSubDialog(false);
          navigate("/subscription");
        }
      }
    }
  }, [cancelSubscriptionResult]);

  useEffect(() => {
    if (cancelSubscriptionError) {
      enqueueSnackbar(cancelSubscriptionError.message, {
        variant: "error",
      });
    }
  }, [cancelSubscriptionError]);

  const handleCancelSubscription = () => {
    cancelSubscription({
      variables: {
        subscriptionId,
      },
    });
  };

  if (plans === "freetrial")
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            borderBottom: "1px solid #bfbcbc",
            paddingBottom: "5px",
            marginBottom: "2rem",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Current Subscription
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#6b6969" }}>
            Current subscription details for your selected plan and billing
            information.
          </Typography>
        </Box>
        <Box
          sx={{
            p: 2,
            borderRadius: "12px",
            border: "1px solid #E0E0E0",
            backgroundColor: "#F9F9F9",
          }}
        >
          <Box display="flex" alignItems="center" gap={7} mt={1}>
            <Typography variant="h6" fontWeight="bold">
              Free Trial
            </Typography>
          </Box>

          <Typography color="textSecondary">
            Your Free trial expires on {endDate}
          </Typography>

          <Box
            sx={{
              margin: "1.5rem 0",
            }}
          >
            <Typography color="textSecondary">
              Enjoying your free trial? Get the most out of our services by
              upgrading to a premium plan today!
            </Typography>
            <Button
              onClick={() => navigate("/settings/upgrade-plan")}
              sx={{
                width: "30%",
                marginTop: "12px",
                backgroundColor: "#4d1277",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4d1277",
                  color: "white",
                },
              }}
            >
              Upgrade Plan
            </Button>
          </Box>

          <Typography mt={1} color="textSecondary">
            {currentCount} out of {maxCount} users
          </Typography>

          <LinearProgress
            variant="determinate"
            value={25}
            sx={{ my: 1, height: 6, borderRadius: 3 }}
          />
        </Box>
      </Box>
    );

  return (
    <Box>
      <Elements stripe={stripePromise}>
        <AddNewMethodModal
          open={openAddNewPaymentMethodModal}
          setOpen={setOpenAddNewPaymentMethodModal}
          stripeCustomerId={customerId}
          setCurrentSubscriptionData={setCurrentSubscriptionData}
        />
      </Elements>

      {/* current-subscription-header */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          borderBottom: "1px solid #bfbcbc",
          paddingBottom: "5px",
          marginBottom: "2rem",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Current Subscription
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#6b6969" }}>
          Current subscription details for your selected plan and billing
          information.
        </Typography>
      </Box>
      {!currentSubscriptionData ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box
          sx={{
            p: 2,
            borderRadius: "12px",
            border: "1px solid #E0E0E0",
            backgroundColor: "#F9F9F9",
          }}
        >
          <Box display="flex" alignItems="center" gap={7} mt={1}>
            <Box display="flex" alignItems="center" gap={1} mt={1}>
              <Typography variant="h6" fontWeight="bold">
                {currentSubscriptionData.packageName}
              </Typography>
              <Chip
                label={currentSubscriptionData.interval}
                color="success"
                size="small"
              />
            </Box>

            <Box display="flex" alignItems="center" gap={1} mt={1}>
              <Typography variant="h4" fontWeight="bold">
                ${currentSubscriptionData.price}
              </Typography>
              <Typography color="textSecondary">
                per {currentSubscriptionData.interval}
              </Typography>
            </Box>
          </Box>

          <Typography mt={1} color="textSecondary" sx={{ margin: "2rem 0" }}>
            Your subscription renews on{" "}
            {currentSubscriptionData.renewalDate.split("T")[0]}
          </Typography>

          <Typography mt={1} color="textSecondary">
            {currentSubscriptionData.currentUsersCount} out of{" "}
            {currentSubscriptionData.maxUsersCount} users
          </Typography>

          <LinearProgress
            variant="determinate"
            value={
              currentSubscriptionData.maxUsersCount > 0
                ? (currentSubscriptionData.currentUsersCount /
                    currentSubscriptionData.maxUsersCount) *
                  100
                : 0
            }
            sx={{ my: 1, height: 6, borderRadius: 3 }}
          />
        </Box>
      )}

      {/* card-header */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          borderBottom: "1px solid #bfbcbc",
          paddingBottom: "5px",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Payment method
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#6b6969" }}>
          Update your billing details and address
        </Typography>
      </Box>
      {/* card details component */}
      {!currentSubscriptionData ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          {/* card details left */}
          <Box>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "bold", color: "black" }}
            >
              Card details
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#6b6969" }}>
              Select default payment method
            </Typography>
          </Box>
          {/* card details right */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "60%",
              margin: "auto",
              gap: "1.5rem",
            }}
          >
            {currentSubscriptionData.paymentMethods.map(
              (card: IPaymentMethod) => (
                <PaymentCard
                  key={card.id}
                  paymentId={card.id}
                  brand={card.brand}
                  cvc={card.last4}
                  expDate={card.expiry}
                  isDefault={card.isDefault}
                  isExpired={false}
                  onDelete={handleDelete}
                  onSetDefault={() => handleSetDefault(card.id)}
                />
              ),
            )}
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              sx={{ cursor: "pointer", color: "text.secondary" }}
              onClick={handleAddPayment}
            >
              <AddIcon fontSize="small" />
              <Typography fontWeight="medium">
                Add new payment method
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      {/* billing-header */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          borderBottom: "1px solid #bfbcbc",
          paddingBottom: "5px",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Billing history
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#6b6969" }}>
          Access all your previous invoices
        </Typography>
      </Box>
      {/* billing history */}
      {!currentSubscriptionData ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <BillingHistory inVoiceDataArray={inVoiceDataArray} />
      )}

      {currentSubscriptionData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1.5rem",
          }}
          onClick={() => setOpenCancelSubDialog(true)}
        >
          <Button
            sx={{
              backgroundColor: "#4d1277",
              color: "white",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#4d1277",
                color: "white",
              },
            }}
          >
            Cancel Your Subscription
          </Button>
        </Box>
      )}

      <DeleteConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onDelete={handleDeletePayment}
        cardBrand={cardBrand}
        cardExpiry={cardExpiry}
        loader={removePaymentMethodForCustomersLoading}
      />
      <CancelSubscriptionConfirmationDialog
        open={openCancelSubDialog}
        onClose={() => setOpenCancelSubDialog(false)}
        onDelete={handleCancelSubscription}
        loader={cancelSubscriptionLoading}
        currentSubscriptionData={currentSubscriptionData}
      />
    </Box>
  );
};

export default Billing;
