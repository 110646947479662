import { gql } from "@apollo/client";

const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($subscriptionId: ID!, $accessToken: String) {
    cancelSubscription(
      subscriptionId: $subscriptionId
      accessToken: $accessToken
    ) {
      success
      error
      data {
        id
        status
      }
    }
  }
`;

export default CANCEL_SUBSCRIPTION;
