import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: 9999,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.light,
  },
  pricing__main_top: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    //
  },
  pricing_top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "50px",
  },
  subscription_intro_text: {
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: "400",
    color: "black",
  },
  subscription_text: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "600",
    color: "black",
  },
  subscription_btn: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "500",
    padding: "10px 15px",
    backgroundColor: "#4d1277",
    color: "white",
    "&:hover": {
      backgroundColor: "#4d1277",
      color: "white",
    },
  },
  main_container: {
    maxWidth: "1440px",
    width: "100%",
    padding: "20px",
    margin: "auto",
    boxSizing: "border-box",
  },
  flexContainer: {
    display: "flex",
    gap: "20px",
    "&>div": { flex: 1 },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  pricing_container: {
    // marginTop: "7rem !important",
    maxWidth: "1280px",
    width: "100%",
    padding: "20px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  settingsWidth: {
    maxWidth: "98%",
  },
  subscribe_text: {
    lineHeight: "21px",
    letterSpacing: "0",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffe5e5",
    padding: "1rem",
    borderRadius: "12px",
  },
  subscribe_icon: {
    marginRight: "10px",
    color: "red",
  },
  recommendation_text: {
    position: "absolute",
    left: "30px",
    top: "-53px",
    maxWidth: "400px",
    display: "inline-flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    //
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    //
    fontWeight: "600",
    fontSize: "11px",
    lineHeight: "16px",
  },
  recommendation_arrow: {
    height: "35px",
    width: "35px",
    marginRight: "10px",
  },
  plan_container: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    gap: "20px",
    // alignItems: "stretch",
    marginTop: "20px",
  },

  package_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    //
    height: "auto",
    flex: 1,
    //   boxShadow: "0px 8px 64px 0px #0000001A",
    boxShadow:
      "0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06)",
    //
    backgroundColor: "white",
    fontSize: "14px",
    lineHeight: "22px",
    color: "var(--content-basic-primary)",
    padding: "24px 20px 33px",
    position: "relative",
    // height: "100%",
    boxSizing: "border-box",
    // maxWidth: "262px",
    border: "2px solid #eeeeee",
    borderRadius: "15px",
  },
  package_container_header_container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "6px",
  },
  package_container_plan: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    //
    gap: "7px",
    fontWeight: "600",
    fontSize: "11px",
    lineHeight: "16px",
    background: "#e6d9ec",
    borderRadius: "12px",
    //
    padding: "0 10px",
    // width: "94px",
    // height: "16px",
    // marginLeft: "20px",
    color: "#4d1277",
  },
  package_container_name: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "600",
    textAlign: "left",
  },
  package_container_description: {
    marginBottom: "20px",
    color: "#635f5f",
  },
  package_container_pricing_container: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  package_container_pricing_figure: {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "flex-end",
    //
    fontWeight: "800",
    fontSize: "30px",
    color: "black",
    marginRight: "10px",
  },
  package_container_pricing_figure_dollar: {
    fontSize: "15px",
    alignSelf: "flex-end",
    height: "auto",
    fontWeight: "600",
  },
  perUserText: {
    fontSize: "12px",
    color: "#635f5f",
  },
  package_container_btn: {
    padding: "11px 14px",
    borderRadius: "8px",
    //
    backgroundColor: "white",
    color: "#4d1277",
    //
    transitionDuration: ".2s",
    transitionProperty: "border,color,background-color",
    transitionTimingFunction: "cubic-bezier(.64,0,.35,1)",
    border: "2px solid #4d1277",
    cursor: "pointer",
    textAlign: "center",
    textDecoration: "none",
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: "600",
    //
    margin: "16px 0",
    "&:hover": {
      backgroundColor: "#4d1277 !important",
      color: "white",
    },
  },
  package_container_features: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  package_container_features_text: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "3px",
    lineHeight: "21px",
    letterSpacing: "0",
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    padding: "4px 0px",
  },
  package_container_features_icon: {
    width: "15px",
    height: "15px",
    lineHeight: "10px",
    marginRight: "4px",
    backgroundColor: "#e6d9ec",
    borderRadius: "50%",
    color: "#4d1277",
    border: "1px solid #4d1277",
  },
  selectorGroup: {
    display: "flex",
    flexDirection: "column",
  },
  plan_text: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "600",
    color: "black",
  },
  feature_text: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    color: "#0059e1",
    textAlign: "center",
    margin: "3rem 0",
    cursor: "pointer",
  },
  progressIndicator: {
    marginTop: "5px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
