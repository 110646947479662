import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.light,
  },
  label: {
    color: "#fff",
  },
  listItem: {
    display: "block",
    padding: 0,
    poPoppinsEvents: "none",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  list: {
    marginLeft: "3px",
  },
  listItemButton: {
    //   minHeight: 48,
    justifyContent: "center",
    textDecoration: "none",
    margin: "5px",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "5px",
    borderRadius: "5px",
    height: "30px",
    "&:hover": {
      backgroundColor: "#e6d9ec !important",
      boxShadow: `0 0 5px ${theme.palette.primary.main} !important`,
    },
    "&:hover .MuiListItemText-root, &:hover .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
  },
  listItemIcon: {
    justifyContent: "center",
    minWidth: 0,
  },
  upperListItem: {
    display: "block",
    paddingRight: 2,
    paddingLeft: 2,
    marginTop: "10px",
  },

  listItemButtonTop: {
    textDecoration: "none",
    justifyContent: "center",
    margin: "5px",
    borderRadius: "5px",
    height: "30px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  textColorHover: {
    color: "#4d1277",
  },
  //
  listItemButtonOpen: {
    justifyContent: "initial !important",
  },
  listItemButtonIcon: {
    minWidth: 0,
    marginRight: "auto",
    marginLeft: "3px",
    justifyContent: "center",
  },
  listItemButtonIconOpen: {
    marginRight: "3 !important",
  },
  drawer: {
    position: "fixed",
    zIndex: 999,
    left: 0,
    top: 0,
    height: "100vh",
    width: "4.166%",
    backgroundColor: "#f5f5f5",
    transition: "width 0.2s ease-in-out",
  },
  top: {
    borderRadius: "5px",
    width: "40px",
    marginLeft: 5,
    marginTop: -15,
    height: "30px",
    display: "flex",
    alignItem: "center",
    position: "fixed",
    paddingTop: 1.5,
    justifyContent: "center",
    // border: "1px solid blue",
  },
  icon: {
    fontSize: 25,
    position: "fixed",
  },
  iconHover: {
    color: "#4d1277",
  },
  back: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  full: {
    marginRight: -5,
  },
  logo: {
    overflow: "visible",
    width: "42px",
    height: "40px",
    top: 13,
    left: 10,
    position: "fixed",
  },
  navListText: {
    marginTop: 0,
    textAlign: "left",
    color: theme.palette.primary.main,
  },
  listLink: {
    textDecoration: "none",
    display: "flex",
    color: "#777777",
  },
  avatar: {
    marginLeft: 10,
    cursor: "pointer",
  },

  linkSelectedtext: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 5,
    color: "#4D1277",
    pointerEvents: "none",
    textDecoration: "none",
  },

  logout: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Poppins",
    opacity: 1,
    textDecoration: "none",
    color: "red",
  },
  logoutOpen: {
    opacity: 1,
  },

  listBelow: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    width: 0,
  },

  logoutButton: {
    marginLeft: 3,
    px: 2.5,
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    justifyContent: "center",
  },
  logoutButtonOpen: {
    justifyContent: "initial !important",
  },
  logoutText: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Poppins",
    // opacity: 0,
    textDecoration: "none",
    color: "red",
    display: "none",
  },
  logoutTextOpen: {
    opacity: 1,
    display: "block",
  },
  // logoutIcon: {
  //   marginLeft: 55,
  // },
  logoutIcon: {
    minWidth: "0",
    marginLeft: "15px",
  },
  logoutIconOpen: {
    // marginLeft: 55,
    marginLeft: "-3px",
  },
  help: {
    height: "30px",
    alignContent: "center",
    padding: 0.5,
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Poppins",
    // marginLeft: 30,
    marginLeft: 20,
    marginTop: 10,
    opacity: 0,
    textDecoration: "none",
    color: "#777777",
    whiteSpace: "pre-wrap",
    // overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  // listItemIcon: {
  //   minWidth: 0,
  //   marginRight: "auto",
  //   justifyContent: "center",
  // },
  listItemIconOpen: {
    marginRight: 28,
  },
}));

export default useStyles;
