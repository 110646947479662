import { gql } from "@apollo/client";

const CREATE_SUBSCRIPTION_INTENT = gql`
  query CreateSubscriptionIntent(
    $customerId: String!
    $priceId: String!
    $noOfAgents: Int!
    $planName: String!
    $accessToken: String
  ) {
    createSubscriptionIntent(
      customerId: $customerId
      priceId: $priceId
      noOfAgents: $noOfAgents
      planName: $planName
      accessToken: $accessToken
    ) {
      success
      error
      data {
        subscriptionId
        clientSecret
      }
    }
  }
`;
export default CREATE_SUBSCRIPTION_INTENT;
