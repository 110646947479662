import React from "react";
import {
  Box,
  Typography,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useStyles from "./Styles";

interface Props {
  productName: string;
  cutPrice: number;
  calculatedPrice: number;
  perPeriod: string;
  agentsNumber: number;
  savingAmount: number;
  onSubmit: () => void;
  loading?: boolean;
  btnText: string;
}

const Summary: React.FC<Props> = ({
  productName,
  cutPrice,
  calculatedPrice,
  perPeriod,
  agentsNumber,
  savingAmount,
  onSubmit,
  loading,
  btnText,
}) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.rightContainer}>
      <Typography
        style={{
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "24px",
        }}
      >
        Summary
      </Typography>
      <Box className={classes.rightContainerHead}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>{productName} Plan</Typography>
          <Typography>
            {perPeriod !== "mo" && !!cutPrice && (
              <span style={{ textDecoration: "line-through" }}>
                ${cutPrice}
              </span>
            )}{" "}
            ${calculatedPrice} / {perPeriod}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ color: "#686868" }}>
            Agents: {agentsNumber}
          </Typography>
          {perPeriod !== "mo" && !!savingAmount && (
            <Typography sx={{ color: "#21a961" }}>
              saving {savingAmount}%
            </Typography>
          )}
        </Box>
      </Box>
      {/* divider */}
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#e2e2e4",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "16px 0",
        }}
      >
        <Typography sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          Annual total{" "}
          <Tooltip
            arrow
            placement="left"
            title="The average annual cost based on the chosen LiveChat subscription plan and add-ons in your cart"
          >
            <ErrorOutlineIcon
              style={{
                fontSize: "18px",
                cursor: "pointer",
                transform: "rotate(180deg)",
              }}
            />
          </Tooltip>
        </Typography>
        <Typography>${calculatedPrice}</Typography>
      </Box>
      {/* divider */}
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#e2e2e4",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "16px 0",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          Prorated charge due today{" "}
          <Tooltip
            arrow
            placement="left"
            title="When you upgrade your plan in the middle of your billing cycle, we charge a pro-rated difference between what you’ve already paid and the cost of the new plan for the remaining days in your billing cycle."
          >
            <ErrorOutlineIcon
              style={{
                fontSize: "18px",
                cursor: "pointer",
                transform: "rotate(180deg)",
              }}
            />
          </Tooltip>
        </Typography>
        <Typography>${calculatedPrice}</Typography>
      </Box>
      {/* divider */}
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#e2e2e4",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "16px 0",
        }}
      >
        <Typography
          style={{
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
          }}
        >
          Billed now
        </Typography>
        <Typography
          style={{
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
          }}
        >
          ${calculatedPrice}
        </Typography>
      </Box>
      <Button
        style={{
          backgroundColor: "#0059e1",
          color: "white",
          padding: "11px 16px",
          borderRadius: "10px",
        }}
        // onClick={() => setStep(3)}
        onClick={onSubmit}
        disabled={loading}
      >
        {btnText}{" "}
        {loading && (
          <CircularProgress
            size={15}
            style={{ color: "white", marginLeft: "5px" }}
          />
        )}
      </Button>
      <Typography
        sx={{
          marginTop: "16px",
          color: "#808080",
          fontWeight: "400",
          fontSize: "13px",
          lineHeight: "20px",
        }}
      >
        You’ll be charged for individual products at the end of their free
        trial(s). Your subscription will continue until you cancel.
      </Typography>
    </Box>
  );
};

export default Summary;
