import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    // padding: "2rem",
    padding: "0 2rem",
    backgroundColor: "white",
  },
  upperBox: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    background: "white",
    paddingTop: "2rem",
  },
  planNameContainer: {
    width: "100%",
    fontSize: "1.25rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    fontWeight: 600,
  },
  planNameText: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  planNamePrice: {
    color: "black",
    fontSize: "13px",
  },
  planNameBtn: {
    border: "1px solid #4d1277",
    borderRadius: "8px",
    backgroundColor: "#4d1277",
    color: "white",
    paddingLeft: "12px",
    paddingRight: "12px",
    "&:hover": {
      backgroundColor: "#4d1277",
      color: "white",
      opacity: "0.8",
    },
    marginTop: "1.2rem",
  },
  popularPlanText: {
    position: "absolute",
    top: "-1.6rem",
    fontSize: "10px",
    borderRadius: "50px",
    backgroundColor: "black",
    padding: "3px 0.5rem",
    color: "white",
  },
  planNameBtnEnterprise: {
    border: "2px solid #4d1277",
    borderRadius: "8px",
    backgroundColor: "white",
    color: "#4d1277",
    paddingLeft: "12px",
    paddingRight: "12px",
    "&:hover": {
      backgroundColor: "white",
      color: "#4d1277",
      opacity: "0.8",
    },
    marginTop: "1.2rem",
  },
  intervalText: {
    lineHeight: "21px",
    letterSpacing: "0",
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
  },
  planHeaderContainer: {
    // paddingLeft: "2.25rem",
    // paddingRight: "2.25rem",
    padding: "1rem 2.25rem",
    display: "grid",
    // gridTemplateColumns: "52% 12% 12% 12% 12%",
    // gridTemplateColumns: "48% 14% 14% 14% 14%",
    gridTemplateColumns: "42% 16% 16% 16% 16%",
    alignItems: "center",
    borderColor: "#e2e2e4",
  },
  planFeatureRow: {
    display: "grid",
    // gridTemplateColumns: "52% 12% 12% 12% 12%",
    // gridTemplateColumns: "48% 14% 14% 14% 13%",
    gridTemplateColumns: "42% 16% 16% 16% 15%",
    alignItems: "center",
    padding: "10px 1.5rem", // py-2 px-6
    borderBottom: "1px solid #e2e2e4",
  },
  //
  loaderBox: { width: "100%", display: "flex", justifyContent: "center" },
  loaderStyle: { color: "#4d1277", size: 15 },
  categoryText: {
    backgroundColor: "#f6f6f7",
    padding: "15px 10px",
    lineHeight: "24px",
    letterSpacing: "0px",
    fontSize: "18px",
    fontWeight: "500",
    fontStyle: "normal",
  },
  featureTextBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
  },
  featureText: {
    fontSize: "14px",
    lineHeight: "22px",
  },
  tooltip: {
    color: "#8e8e90",
    cursor: "pointer",
    "&:hover": {
      color: "black",
    },
    transform: "rotate(180deg)",
  },
  selectedFeatureBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  checkIcon: {
    color: "#003288", // Outer color of the icon
    "& .MuiSvgIcon-root": {
      fill: "#9dceff", // Inner color of the check
    },
  },
  notCheckedIcon: {
    textAlign: "right",
    backgroundColor: "#8d8d8f",
    height: "2px",
    width: "1rem",
  },
  textOfFeat: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
