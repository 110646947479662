import { gql } from "@apollo/client";

const ADD_PAYMENT_METHOD_FOR_CUSTOMERS = gql`
  mutation addPaymentMethodForCustomer(
    $stripeCustomerId: String!
    $paymentMethodId: String!
  ) {
    addPaymentMethodForCustomer(
      stripeCustomerId: $stripeCustomerId
      paymentMethodId: $paymentMethodId
    ) {
      success
      message
      paymentMethod {
        id
        brand
        last4
        expiry
        isDefault
      }
    }
  }
`;

export default ADD_PAYMENT_METHOD_FOR_CUSTOMERS;
