/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { useSnackbar, closeSnackbar } from "notistack";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import useNetwork from "src/hooks/useNetwork";
import SETUSERALERT from "src/graphql/mutation/freeAccess/setUserAlert";
import { useMutation } from "@apollo/client";

const NullifiedAccessPeriodMessage = () => {
  const isOnline = useNetwork();
  const [prevIsOnline, setPrevIsOnline] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { alert, agentId } = useActiveUserDetail();

  const [setUserAlert, { data: setUserAlertData, error: setUserAlertError }] =
    useMutation(SETUSERALERT, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (setUserAlertData?.setUserAlert?.success) {
      closeSnackbar();
    }
  }, [setUserAlertData]);

  useEffect(() => {
    if (setUserAlertError) {
      enqueueSnackbar(setUserAlertError.message, {
        variant: "error",
      });
    }
  }, [setUserAlertError]);

  useEffect((): any => {
    if (!isOnline) {
      enqueueSnackbar("Internet connection lost. Please check your network.", {
        variant: "error",
      });
    } else if (isOnline && prevIsOnline) {
      enqueueSnackbar("Internet connection recovered.", {
        variant: "success",
      });
    }
  }, [isOnline, prevIsOnline, enqueueSnackbar]);

  useEffect(() => {
    if (!isOnline) setPrevIsOnline(true);
  }, [isOnline]);

  const snackbarAction = useCallback(
    () => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontSize: "14px" }}>
          Your FreeAccess plan has expired, leading to restrictions of features
          in accordance with the subscription.
        </Typography>
      </Box>
    ),
    [agentId], // Dependencies to prevent unnecessary recreation
  );

  useEffect(() => {
    if (alert) {
      enqueueSnackbar("Expiration of freeAccessPlan", {
        variant: "info",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        autoHideDuration: null,
        action: snackbarAction,
      });
    }
  }, [alert]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (agentId) {
        setUserAlert({ variables: { agentId } });
      }
    }, 5000);

    return () => clearTimeout(timeout); // Cleanup on unmount
  }, [agentId]);

  return null;
};

export default NullifiedAccessPeriodMessage;
