interface ILoggedInUser {
  id: string;
  designation: {
    paneltype: string;
  };
  mainSuperAdminId: string;
  accessToken: string;
  name: string;
  picture: string | null;
  number: string;
  pseudonym: string;
  username: string;
  stripe_id: string;
  plans: string;
  endDate: string;
  accessPeriodEndDate: string;
  hasTrial: string;
  alert: string;
  subscriptionId: string;
  invoice_url: string;
  currentCount: number;
  maxCount: number;
  stripe_subscription_status: string;
}

const setAuthDataInLocalStorage = (user: ILoggedInUser): boolean => {
  console.log("user user", user);
  if (!user) return false;
  try {
    const {
      id,
      designation,
      mainSuperAdminId,
      name,
      accessToken,
      picture,
      number,
      pseudonym,
      username,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      stripe_id,
      plans,
      hasTrial,
      endDate,
      accessPeriodEndDate,
      alert,
      subscriptionId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      invoice_url,
      //
      currentCount,
      maxCount,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      stripe_subscription_status,
    } = user;
    const paneltype = designation?.paneltype;
    localStorage.setItem("userName", name);
    const ActiveUserData = {
      id,
      paneltype,
      mainSuperAdminId,
      picture,
      number,
      pseudonym,
      username,
      stripe_id,
      plans,
      hasTrial,
      endDate,
      accessPeriodEndDate,
      alert,
      subscriptionId,
      invoice_url,
      //
      currentCount,
      maxCount,
      stripe_subscription_status,
    };
    localStorage.setItem("ActiveUserdetail", JSON.stringify(ActiveUserData));
    localStorage.setItem("accessToken", accessToken);
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error while setting data in localStorage:", err);
    return false;
  }
};

export default setAuthDataInLocalStorage;
