import { createSlice } from "@reduxjs/toolkit";

interface IMe {
  designation: string;
  paneltype: string;
  id: string;
  mainSuperAdminId: string;
  name: string;
  number: null | number;
  onlineStatus: boolean;
  pages: null | any;
  pagesData: null | any;
  picture: string;
  pseudonym: null | string;
  settings: null | any;
  switchaccountsettings: null | any;
  username: string;
  currentCount: number;
  maxCount: number;
  stripe_subscription_status: string;
}

export interface IMeData {
  me: IMe | null;
}

const initialState: IMeData = {
  me: null,
};

const meSlice = createSlice({
  initialState,
  name: "ME",
  reducers: {
    meHandler: (state, action) => {
      return {
        ...state,
        me: action.payload,
      };
    },
  },
});

export const { meHandler } = meSlice.actions;
export default meSlice.reducer;
