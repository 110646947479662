import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  heading: {
    lineHeight: "24px",
    letterSpacing: "0",
    fontSize: "18px",
    fontWeight: "600",
    fontStyle: "normal",
    marginBottom: "16px",
  },
  mainContainer: {
    "&>form": {
      flex: 1,
      background: "white",
      padding: "20px",
    },
  },
  leftContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    width: "65%",
    height: "fit-content",
    borderRadius: "6px",
    padding: "20px 33px 20px 16px",
    border: "1px solid rgb(207, 211, 213)",
    marginBottom: "12px",
    position: "relative",
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  planText: {
    display: "flex",
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  rightContainer: {
    display: "flex",
    height: "fit-content",
    flexDirection: "column",
    boxSizing: "border-box",
    background: "#f5f5f5",
    padding: "24px 30px",
    width: "35%",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "22px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  rightContainerHead: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "22px",
    margin: "16px 0",
  },
}));

export default useStyles;
